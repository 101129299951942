import {DeviceDataType} from "./type";
import {vm} from "../../../lib/scratch-vm";
import { IS_ARDUINO_MODE, IS_WEB_PLATFORM } from "config/config";
import ScratchBlocks from "lib/scratch-blocks";

// 加载设备
export const loadDevice = (item: DeviceDataType): Promise<any> => {
    const hasDevice = !!vm.runtime.deviceManager.currentDevice;
    // 3.加载设备target
    return vm.extensionManager.loadDevice(item.deviceIdWithVersion)
        .then(() => {
            // 之前没有主板的情况下, 加载主板不需要刷新小模块
            if (!hasDevice) return;
            // 刷新新设备支持的小模块, 或移除所有旧设备的小模块
            return vm.runtime.deviceManager.refreshAllDeviceModules(item.deviceIdWithVersion);
        })
        .then(() => {
            // 之前没有主板的情况下, 加载主板不需要清空workspace
            if (hasDevice) {
                // 移除targets上的所有block
                vm.runtime.targets.forEach(target => target.blocks.deleteAllBlocks())
            }
            // 加载默认block, 程序头的opcode都是start
            if (IS_ARDUINO_MODE && ScratchBlocks.Blocks[`${item.deviceIdWithVersion}_start`]) {
                vm.emit("addDeviceDefaultBlocks", `${item.deviceIdWithVersion}_start`, hasDevice)
            } else {
                // 刷新workspace
                vm.refreshWorkspace();
            }

        })
}

// 移除设备
export const unloadDevice = () => {
    return vm.runtime.deviceManager.deleteDevice()
        .then(() => {
            // 移除所有旧设备的小模块
            return vm.runtime.deviceManager.removeAllDeviceModules();
        })
        .then(() => {
            // 刷新workspace中的block
            vm.refreshWorkspace();
        })
}
