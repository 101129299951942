import {vm} from "lib/scratch-vm";
import * as DataFunc from "../arduino/data"
import * as ControlFunc from "../arduino/control"
import * as OperatorFunc from "../arduino/operator"
import * as ProceduresFunc from "../arduino/procedures"

const builtinFunc = {
    ...DataFunc,
    ...ControlFunc,
    ...OperatorFunc,
    ...ProceduresFunc,
}


export const getBlockFuncByOpcode = (opcode: string) => {
    // 1. 默认block
    if (
        opcode.indexOf("data_") === 0 ||
        opcode.indexOf("control_") === 0 ||
        opcode.indexOf("operator_") === 0 ||
        opcode.indexOf("procedures_") === 0 
    ) {
        const deviceObj = vm.runtime.deviceManager.getDeviceExtensionObj();
        console.log("deviceObj", deviceObj)
        // 设备扩展中, 是否覆盖内置block
        if (!deviceObj) return builtinFunc[opcode]
        const codeFuncs = deviceObj.getCodePrimitives()
        console.log("codeFuncs", codeFuncs)
        if (codeFuncs&&codeFuncs[opcode]) return codeFuncs[opcode];
        return builtinFunc[opcode]
    }
    // 2.扩展block
    return vm.runtime.getCodeOpcodeFunction(opcode)
}
