import { CodeEditor } from "./code-editor/CodeEditor"
import styles from "./CodeWrapper.module.scss"
import { ControlBar } from "./control-bar/ControlBar"
import { Terminal } from "./terminal/Terminal"

export const CodeWrapper = () => {

    return (
        <div className={styles.codeWrapper}>
            <ControlBar />
            <CodeEditor />
            <Terminal />
        </div>
    )
}