import { useEffect, useRef, useState } from "react"
import styles from "./Terminal.module.scss"
import Lock from "./lock.svg"
import Clock from "./clock.svg"
import AceEditor from 'react-ace';
import { vm } from "lib/scratch-vm";
import { SerialMessage } from "service/device-manager/deviceManager"
import { Button, Checkbox, Flex, message, Radio } from 'antd';
import ClearIcon from "./clear.svg"
import { CaretDownFilled, UnorderedListOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import 'brace/theme/terminal';
import Input from "antd/es/input/Input"
import DFIcon from "component/df-icon/DFIcon"

export const Terminal = () => {
    const [tabIndex, setTabIndex] = useState(0)
    const editorRef = useRef(null);
    const [outputInfo, setOutputInfo] = useState("")
    const [serialInfo, setSerialInfo] = useState<SerialMessage[]>([])
    const [scrollLock, setScrollLock] = useState(false) // 滚动锁定
    const [showTimestamp, setShowTimestamp] = useState(false) // 时间戳
    const [isSerialOpen, setIsSerialOpen] = useState(false) // 串口开关状态
    const [showBaud, setShowBaud] = useState(vm.runtime.deviceManager.getSerialBaudRate()) // 波特率
    const [sendData, setSendData] = useState("") // 串口发送数据
    const [IsShowMore, setIsShowMore] = useState(false) // 更多显示
    const [ending, setEnding] = useState("1") // 结束符

    // 编译/烧录时, 跳转到输出打印
    useEffect(() => {
        vm.runtime.deviceManager._showOutputCallback = () => {
            setTabIndex(0)
        }
        return () => {
            vm.runtime.deviceManager._showOutputCallback = null;
        }
    }, [])

    // 监听输出信息
    useEffect(() => {
        vm.runtime.deviceManager.listenOutputMessage((msg: string) => {
            setOutputInfo(msg)
        })
        return () => {
            vm.runtime.deviceManager.listenOutputMessage(null)
        }
    }, [])

    useEffect(() => {
        // 监听串口状态
        vm.runtime.deviceManager.listenSerialStatus((open: boolean) => {
            setIsSerialOpen(open)
        })
        // 监听串口信息
        vm.runtime.deviceManager.listenSerialMessage((msg: SerialMessage[]) => {
            setSerialInfo([...msg])
        })
        return () => {
            vm.runtime.deviceManager.listenSerialMessage(null)
            vm.runtime.deviceManager.listenSerialStatus(null)
        }
    }, [])

    // 当输出信息变化, 滚动到底部
    useEffect(() => {
        if (!editorRef.current || scrollLock) return;
        // 输出太多
        (editorRef.current as any).editor?.scrollToLine(9999999);
    }, [outputInfo, serialInfo])

    // 清除显示信息
    const onClear = () => {
        if (tabIndex === 0) {
            vm.runtime.deviceManager.clearOutputMessage()
        } else {
            vm.runtime.deviceManager.clearSerialMessage()
        }
    }

    // 滚动锁定
    const onScrollLock = () => {
        setScrollLock(v => !v)
    }

    // 时间戳
    const onShowTimestamp = () => {
        setShowTimestamp(v => !v)
    }

    let showMessage = "";
    if (tabIndex === 0) {
        showMessage = outputInfo;
    } else if (showTimestamp) { // 串口监视, 带时间戳
        showMessage = serialInfo.map(item => `[${item.time}] ${item.content}\r\n`).join("");
    } else {
        showMessage = serialInfo.map(item => item.content).join("");
    }
    // 串口打开
    const onSerialOpen = () => {
        if (isSerialOpen) return
        // todo: loading状态, 防止重复点击?
        vm.runtime.deviceManager.openSerial()
            // .then(() => setIsSerialOpen(true))
            .catch((e) => message.error(e))
            .finally(() => {

            })
    }

    // 串口关闭
    const onSerialClose = () => {
        if (!isSerialOpen) return
        vm.runtime.deviceManager.closeSerial()
            // .then(() => setIsSerialOpen(false))
            .catch((e) => message.error(e))
            .finally(() => {

            })
    }

    // 切换波特率
    const onBaudChange = ({ key }) => {
        // tips: 如果串口已经打开, 会更新当前波特率
        vm.runtime.deviceManager.updateSerialBaudRate(parseInt(key), (err) => {
            if (!err) {
                // 修改显示的baud
                setShowBaud(key)
            } else {
                message.error("修改波特率失败!")
            }
        })
    }

    // 串口发送
    const onSerialSend = () => {
        if (sendData) {
            // 加上结束符
            let endingStr = ""
            switch (ending) {
                case "1": endingStr = ""; break;
                case "2": endingStr = "\n"; break;
                case "3": endingStr = "\r"; break;
                case "4": endingStr = "\r\n"; break;
            }
            vm.runtime.deviceManager.serialWrite(sendData + endingStr)
        }
        setSendData("")
    }

    // 发送数据change
    const onSerialSendDataChange = (e) => {
        setSendData(e.target.value)
    }

    const onSetDTR = () => {

    }

    const onSetRTS = () => {

    }

    // 设置结束符
    const onEndingChange = ({ key }) => {
        console.log(key)
        setEnding(key)
    }

    const endingItems = [
        { key: '1', label: "无结束符" },
        { key: "2", label: "换行符" },
        { key: '3', label: "回车" },
        { key: '4', label: "回车加换行" },
    ]

    return (
        <div className={styles.terminalWrapper}>
            <div className={styles.header}>
                <div className={styles.tabs}>
                    <div className={`${styles.tab} ${tabIndex === 0 ? styles.selected : ""}`} onClick={() => setTabIndex(0)}>输出</div>
                    <div className={`${styles.tab} ${tabIndex === 1 ? styles.selected : ""}`} onClick={() => setTabIndex(1)}>串口监视</div>
                </div>
                <div className={styles.settings}>
                    {tabIndex === 1 && <div className={`${styles.setting} ${showTimestamp && styles.selected}`} title={"显示时间戳"} onClick={onShowTimestamp}>
                        <img className={styles.image} src={Clock} alt="" />
                    </div>}
                    <div className={`${styles.setting} ${scrollLock && styles.selected}`} title={"滚动锁定"} onClick={onScrollLock}>
                        <img className={styles.image} src={Lock} alt="" />
                    </div>
                    <div className={styles.setting} title={"清除"} onClick={onClear}>
                        <img className={styles.image} src={ClearIcon} alt="" style={{ width: "20px", height: "21px" }} />
                    </div>
                </div>
            </div>
            <div className={styles.content}>
                <AceEditor
                    // className={styles.terminal}
                    style={{ position: "relative", height: "100%" }}
                    editorProps={{ $blockScrolling: true }}
                    focus={false}
                    fontSize={12}
                    mode="text"
                    readOnly={true}
                    setOptions={{
                        showLineNumbers: false,
                        tabsize: 2,
                    }}
                    showGutter={false}
                    theme="terminal"
                    ref={editorRef}
                    value={showMessage}
                />
            </div>
            {tabIndex === 1 && (
                <div className={styles.menuBar}>
                    {/* 打开关闭串口 */}
                    <div className={styles.openButton}>
                        <Radio.Group value={isSerialOpen ? "open" : "close"}>
                            <Radio.Button value="open">
                                <div className={styles.radioContent} onClick={onSerialOpen} title="打开串口">
                                    {/* <img className={`${styles.image} ${!isSerialOpen&&styles.disabled}`} src={OpenSerialIcon} /> */}
                                    <DFIcon name="open-serial" size={24} color={isSerialOpen ? "" : "#b4b4b4"} />
                                </div>
                            </Radio.Button>
                            <Radio.Button value="close">
                                <div className={styles.radioContent} onClick={onSerialClose} title="关闭串口">
                                    {/* <img className={`${styles.image} ${isSerialOpen&&styles.disabled}`} src={CloseSerialIcon} /> */}
                                    <DFIcon name="close-serial" size={24} color={isSerialOpen ? "#b4b4b4" : ""} />
                                </div>
                            </Radio.Button>
                        </Radio.Group>
                    </div>
                    <Dropdown
                        menu={{
                            items: [1200, 2400, 4800, 9600, 19200, 38400, 57600, 76800, 115200, 230400].map(key => ({ key, label: `${key} 波特率` })),
                            onClick: onBaudChange
                        }}
                        placement="top"
                        trigger={['click']}
                    >
                        <Space className={styles.selectBaud}>
                            {`${showBaud}`}
                            {/* <DownOutlined /> */}
                            <CaretDownFilled />
                        </Space>
                    </Dropdown>
                    <div className={styles.inputWrapper}>
                        {/* 发送 */}
                        <Input
                            value={sendData}
                            placeholder="Enter to send message"
                            disabled={!isSerialOpen}
                            onChange={onSerialSendDataChange}
                            onPressEnter={onSerialSend}
                        />
                        <Button
                            // type="primary"
                            disabled={!isSerialOpen}
                            onClick={onSerialSend}
                        >
                            发送
                        </Button>
                    </div>
                    <div className={styles.more} onClick={() => setIsShowMore(v => !v)}>
                        {/* 更多 */}
                        <UnorderedListOutlined />
                        {IsShowMore && <div className={styles.moreContent} onClick={e => e.stopPropagation()}>
                            {/* <div className={styles.moreItem}>
                                <Checkbox onChange={onSetRTS}>RTS</Checkbox>
                                <Checkbox onChange={onSetDTR}>DTR</Checkbox>
                            </div> */}
                            <div className={styles.moreItem}>
                                <Dropdown
                                    menu={{
                                        items: endingItems,
                                        onClick: onEndingChange
                                    }}
                                    placement="top"
                                    trigger={['click']}
                                >
                                    <div className={styles.moreItemDropdown}>
                                        {endingItems.find(item => item.key === ending)?.label}
                                        {/* <DownOutlined /> */}
                                        <CaretDownFilled />
                                    </div>
                                </Dropdown>
                            </div>
                        </div>}
                    </div>
                </div>
            )}
        </div>
    )
}
