import ScratchBlocks from "scratch-blocks";
import { DataType } from "../data-type";

export const redefineControlBlocks = () => {
    ScratchBlocks.Blocks['control_return'] = {
        init: function () {
            this.jsonInit({
                "message0": ScratchBlocks.Msg.CONTROL_RETURN,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "OPERAND1",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                    },
                ],
                "category": ScratchBlocks.Categories.control,
                "extensions": ["shape_statement"],
                "colour": '#ff6680',
                "colourSecondary": '#ff4d6a',
                "colourTertiary": '#ff4d6a'
            });
        }
    };

    ScratchBlocks.Blocks['control_repeat'] = {
        init: function () {
            this.jsonInit({
                "id": "control_repeat",
                "type": "control_repeat",
                "message0": ScratchBlocks.Msg.CONTROL_REPEAT,
                "message1": "%1", // Statement
                "message2": "%1", // Icon
                "lastDummyAlign2": "RIGHT",
                "args0": [
                    {
                        "type": "input_value",
                        "name": "TIMES",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL]
                    }
                ],
                "args1": [
                    {
                        "type": "input_statement",
                        "name": "SUBSTACK"
                    }
                ],
                "args2": [
                    {
                        "type": "field_image",
                        "src": ScratchBlocks.mainWorkspace.options.pathToMedia + "repeat.svg",
                        "width": 24,
                        "height": 24,
                        "alt": "*",
                        "flip_rtl": true
                    }
                ],
                "category": ScratchBlocks.Categories.control,
                "extensions": ["colours_control", "shape_statement"]
            });
        }
    };

    ScratchBlocks.Blocks['control_if'] = {
        init: function () {
            this.jsonInit({
                "type": "control_if",
                "message0": ScratchBlocks.Msg.CONTROL_IF,
                "message1": "%1", // Statement
                "args0": [
                    {
                        "type": "input_value",
                        "name": "CONDITION",
                        "check": "Boolean",
                        "inputTypes": [DataType.BOOL]
                    }
                ],
                "args1": [
                    {
                        "type": "input_statement",
                        "name": "SUBSTACK"
                    }
                ],
                "category": ScratchBlocks.Categories.control,
                "extensions": ["colours_control", "shape_statement"]
            });
        }
    };

    ScratchBlocks.Blocks['control_if_else'] = {
        init: function () {
            this.jsonInit({
                "type": "control_if_else",
                "message0": ScratchBlocks.Msg.CONTROL_IF,
                "message1": "%1",
                "message2": ScratchBlocks.Msg.CONTROL_ELSE,
                "message3": "%1",
                "args0": [
                    {
                        "type": "input_value",
                        "name": "CONDITION",
                        "check": "Boolean",
                        "inputTypes": [DataType.BOOL]
                    }
                ],
                "args1": [
                    {
                        "type": "input_statement",
                        "name": "SUBSTACK"
                    }
                ],
                "args3": [
                    {
                        "type": "input_statement",
                        "name": "SUBSTACK2"
                    }
                ],
                "category": ScratchBlocks.Categories.control,
                "extensions": ["colours_control", "shape_statement"]
            });
        }
    };

    ScratchBlocks.Blocks['control_wait'] = {
        init: function () {
            this.jsonInit({
                "id": "control_wait",
                "type": "control_wait",
                "message0": ScratchBlocks.Msg.CONTROL_WAIT,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "DURATION",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL]
                    }
                ],
                "category": ScratchBlocks.Categories.control,
                "extensions": ["colours_control", "shape_statement"]
            });
        }
    };

    ScratchBlocks.Blocks['control_wait_until'] = {
        init: function () {
            this.jsonInit({
                "type": "control_wait_until",
                "message0": ScratchBlocks.Msg.CONTROL_WAITUNTIL,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "CONDITION",
                        "check": "Boolean",
                        "inputTypes": [DataType.BOOL]
                    }
                ],
                "category": ScratchBlocks.Categories.control,
                "extensions": ["colours_control", "shape_statement"]
            });
        }
    };

    ScratchBlocks.Blocks['control_repeat_until'] = {
        /**
         * Block to repeat until a condition becomes true.
         * @this ScratchBlocks.Block
         */
        init: function () {
            this.jsonInit({
                "type": "control_repeat_until",
                "message0": ScratchBlocks.Msg.CONTROL_REPEATUNTIL,
                "message1": "%1",
                "message2": "%1",
                "lastDummyAlign2": "RIGHT",
                "args0": [
                    {
                        "type": "input_value",
                        "name": "CONDITION",
                        "check": "Boolean",
                        "inputTypes": [DataType.BOOL]
                    }
                ],
                "args1": [
                    {
                        "type": "input_statement",
                        "name": "SUBSTACK"
                    }
                ],
                "args2": [
                    {
                        "type": "field_image",
                        "src": ScratchBlocks.mainWorkspace.options.pathToMedia + "repeat.svg",
                        "width": 24,
                        "height": 24,
                        "alt": "*",
                        "flip_rtl": true
                    }
                ],
                "category": ScratchBlocks.Categories.control,
                "extensions": ["colours_control", "shape_statement"]
            });
        }
    };
}
