import ScratchBlocks from "scratch-blocks";
import { DataType } from "../data-type";

export const redefineProceduresBlocks = () => { 
    // 重写ProcedureUtils.createAllInputs_，arduino模式下创建的自定义函数input有输入限制
    ScratchBlocks.ScratchBlocks.ProcedureUtils.createAllInputs_ = function (connectionMap) {
        var procComponents = this.procCode_.split(/(?=[^\\]%[nbs])/);
        procComponents = procComponents.map(function (c) {
            return c.trim(); // Strip whitespace.
        });
        // Create arguments and labels as appropriate.
        var argumentCount = 0;
        for (var i = 0, component; component = procComponents[i]; i++) {
            var labelText;
            if (component.substring(0, 1) == '%') {
                var argumentType = component.substring(1, 2);
                if (!(argumentType == 'n' || argumentType == 'b' || argumentType == 's')) {
                    throw new Error(
                        'Found an custom procedure with an invalid type: ' + argumentType);
                }
                labelText = component.substring(2).trim();

                var id = this.argumentIds_[argumentCount];

                var input = this.appendValueInput(id);
                if (argumentType == 'b') {
                    input.setCheck('Boolean');
                    input.setInputTypes([DataType.BOOL]);
                } else if (argumentType == 'n') {
                    input.setInputTypes([DataType.INT32_T, DataType.UINT32_T, DataType.FLOAT, DataType.BOOL]);
                } else {
                    input.setInputTypes([DataType.STRING]);
                }
                this.populateArgument_(argumentType, argumentCount, connectionMap, id,
                    input);
                argumentCount++;
            } else {
                labelText = component.trim();
            }
            this.addProcedureLabel_(labelText.replace(/\\%/, '%'));
        }
    };

    ScratchBlocks.Blocks['procedures_call'] = {
        /**
         * Block for calling a procedure with no return value.
         * @this Blockly.Block
         */
        init: function () {
            this.jsonInit({
                "extensions": ["colours_more", "shape_statement", "procedure_call_contextmenu"]
            });
            this.procCode_ = '';
            this.argumentIds_ = [];
            this.warp_ = false;
        },
        // Shared.
        getProcCode: ScratchBlocks.ScratchBlocks.ProcedureUtils.getProcCode,
        removeAllInputs_: ScratchBlocks.ScratchBlocks.ProcedureUtils.removeAllInputs_,
        disconnectOldBlocks_: ScratchBlocks.ScratchBlocks.ProcedureUtils.disconnectOldBlocks_,
        deleteShadows_: ScratchBlocks.ScratchBlocks.ProcedureUtils.deleteShadows_,
        createAllInputs_: ScratchBlocks.ScratchBlocks.ProcedureUtils.createAllInputs_,
        updateDisplay_: ScratchBlocks.ScratchBlocks.ProcedureUtils.updateDisplay_,

        // Exist on all three blocks, but have different implementations.
        mutationToDom: ScratchBlocks.ScratchBlocks.ProcedureUtils.callerMutationToDom,
        domToMutation: ScratchBlocks.ScratchBlocks.ProcedureUtils.callerDomToMutation,
        populateArgument_: ScratchBlocks.ScratchBlocks.ProcedureUtils.populateArgumentOnCaller_,
        addProcedureLabel_: ScratchBlocks.ScratchBlocks.ProcedureUtils.addLabelField_,

        // Only exists on the external caller.
        attachShadow_: ScratchBlocks.ScratchBlocks.ProcedureUtils.attachShadow_,
        buildShadowDom_: ScratchBlocks.ScratchBlocks.ProcedureUtils.buildShadowDom_
    };
    ScratchBlocks.Blocks['procedures_prototype'] = {
        /**
         * Block for calling a procedure with no return value, for rendering inside
         * define block.
         * @this Blockly.Block
         */
        init: function () {
            this.jsonInit({
                "extensions": ["colours_more", "shape_statement"]
            });

            /* Data known about the procedure. */
            this.procCode_ = '';
            this.displayNames_ = [];
            this.argumentIds_ = [];
            this.argumentDefaults_ = [];
            this.warp_ = false;
        },
        // Shared.
        getProcCode: ScratchBlocks.ScratchBlocks.ProcedureUtils.getProcCode,
        removeAllInputs_: ScratchBlocks.ScratchBlocks.ProcedureUtils.removeAllInputs_,
        disconnectOldBlocks_: ScratchBlocks.ScratchBlocks.ProcedureUtils.disconnectOldBlocks_,
        deleteShadows_: ScratchBlocks.ScratchBlocks.ProcedureUtils.deleteShadows_,
        createAllInputs_: ScratchBlocks.ScratchBlocks.ProcedureUtils.createAllInputs_,
        updateDisplay_: ScratchBlocks.ScratchBlocks.ProcedureUtils.updateDisplay_,

        // Exist on all three blocks, but have different implementations.
        mutationToDom: ScratchBlocks.ScratchBlocks.ProcedureUtils.definitionMutationToDom,
        domToMutation: ScratchBlocks.ScratchBlocks.ProcedureUtils.definitionDomToMutation,
        populateArgument_: ScratchBlocks.ScratchBlocks.ProcedureUtils.populateArgumentOnPrototype_,
        addProcedureLabel_: ScratchBlocks.ScratchBlocks.ProcedureUtils.addLabelField_,

        // Only exists on procedures_prototype.
        createArgumentReporter_: ScratchBlocks.ScratchBlocks.ProcedureUtils.createArgumentReporter_,
        updateArgumentReporterNames_: ScratchBlocks.ScratchBlocks.ProcedureUtils.updateArgumentReporterNames_
    };
    ScratchBlocks.Blocks['procedures_declaration'] = {
        /**
         * The root block in the procedure declaration editor.
         * @this Blockly.Block
         */
        init: function () {
            this.jsonInit({
                "extensions": ["colours_more", "shape_statement"]
            });
            /* Data known about the procedure. */
            this.procCode_ = '';
            this.displayNames_ = [];
            this.argumentIds_ = [];
            this.argumentDefaults_ = [];
            this.warp_ = false;
        },
        // Shared.
        getProcCode: ScratchBlocks.ScratchBlocks.ProcedureUtils.getProcCode,
        removeAllInputs_: ScratchBlocks.ScratchBlocks.ProcedureUtils.removeAllInputs_,
        disconnectOldBlocks_: ScratchBlocks.ScratchBlocks.ProcedureUtils.disconnectOldBlocks_,
        deleteShadows_: ScratchBlocks.ScratchBlocks.ProcedureUtils.deleteShadows_,
        createAllInputs_: ScratchBlocks.ScratchBlocks.ProcedureUtils.createAllInputs_,
        updateDisplay_: ScratchBlocks.ScratchBlocks.ProcedureUtils.updateDisplay_,

        // Exist on all three blocks, but have different implementations.
        mutationToDom: ScratchBlocks.ScratchBlocks.ProcedureUtils.definitionMutationToDom,
        domToMutation: ScratchBlocks.ScratchBlocks.ProcedureUtils.definitionDomToMutation,
        populateArgument_: ScratchBlocks.ScratchBlocks.ProcedureUtils.populateArgumentOnDeclaration_,
        addProcedureLabel_: ScratchBlocks.ScratchBlocks.ProcedureUtils.addLabelEditor_,

        // Exist on declaration and arguments editors, with different implementations.
        removeFieldCallback: ScratchBlocks.ScratchBlocks.ProcedureUtils.removeFieldCallback,

        // Only exist on procedures_declaration.
        createArgumentEditor_: ScratchBlocks.ScratchBlocks.ProcedureUtils.createArgumentEditor_,
        focusLastEditor_: ScratchBlocks.ScratchBlocks.ProcedureUtils.focusLastEditor_,
        getWarp: ScratchBlocks.ScratchBlocks.ProcedureUtils.getWarp,
        setWarp: ScratchBlocks.ScratchBlocks.ProcedureUtils.setWarp,
        addLabelExternal: ScratchBlocks.ScratchBlocks.ProcedureUtils.addLabelExternal,
        addBooleanExternal: ScratchBlocks.ScratchBlocks.ProcedureUtils.addBooleanExternal,
        addStringNumberExternal: ScratchBlocks.ScratchBlocks.ProcedureUtils.addStringNumberExternal,
        addStringExternal: ScratchBlocks.ScratchBlocks.ProcedureUtils.addStringExternal,
        addNumberExternal: ScratchBlocks.ScratchBlocks.ProcedureUtils.addNumberExternal,
        onChangeFn: ScratchBlocks.ScratchBlocks.ProcedureUtils.updateDeclarationProcCode_
    };
}
