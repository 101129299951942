import ChildProcessWs from "./websocket/childProcessWs"
export function spawn(cmd: string, args?: Array<any>|any, options?: any) {
    console.log("spawn", cmd, args, options)
    const cp = new ChildProcessWs();
    if (args && !Array.isArray(args)) {
        options = args;
        args = undefined;
    }
    // ws连接成功之后, 发送命令
    cp.once("open", () => {
        cp.sendRemoteNotification("spawn", {
            cmd: cmd,
            args: args,
            options: options
        })
    })
    return cp;
}

export function exec(cmd: string, options?: any, callback?: (err: string|null, stdout?: string, stderr?: string) => void) {
    const cp = new ChildProcessWs();
    console.log("exec", cmd, options)

    if (typeof options === "function") {
        callback = options;
        options = undefined
    }

    // ws连接成功之后, 发送命令
    cp.once("open", () => {
        cp.sendRemoteRequest("exec", {
            cmd: cmd,
            options: options
        }).then(({stdout, stderr}: any) => {
            if (callback) callback(null, stdout, stderr)
        }).catch((err) => {
            if (callback) callback(err)
        })
    })
    return cp;
}

