import { DeviceTarget } from './deviceTarget';
import VM, { vm } from "../../lib/scratch-vm";
import { ExtensionDataType } from 'service/ext-asset-manager/extension/type';
import { message } from 'antd';
import { checkFuzzyVersion } from 'util/compareVersion';
import { IS_ARDUINO_MODE, IS_SCRATCH_MODE, MINDPLUS_MODE } from 'config/config';
import Serialport, { CustomErrorCallback } from 'service/link-adapter/serialport';
import { SetOptions } from 'service/link-adapter/websocket/serialportWs';
import fs from 'service/link-adapter/fs';
import path from "path"
import { getArduinoPath } from 'service/path/assetPath';

export interface SerialMessage {
    time: string
    content: string
}

// 主控相关接口
export class DeviceManager {
    vm: VM
    ONLINE_MODE = 'online'
    UPLOAD_MODE = 'upload'
    _currentDevice?: DeviceTarget
    _devicesInfo: { [deviceId: string]: { config: any, deviceObject: any } }
    _modules = {} // 小模块列表(主板小模块, 舞台小模块)
    _port: string = ""
    _serial: Serialport | null = null
    _serialMessage: SerialMessage[] = []
    _onSerialMessagesCallback: Function | null = null;
    _outputMessage: string = ""
    _onOutputMessagesCallback: Function | null = null;
    _baudRate: number = 9600
    _showOutputCallback: Function | null = null // 切换到输出打印框
    _onSerialStatusCallback: Function | null = null// 监听串口打开/关闭状态
    _setOptions: SetOptions = {}
    constructor(vm) {
        this.vm = vm;
        this._devicesInfo = {} // {[deviceId: string]: object}主控的功能接口
    }

    // 创建主控
    async createDevice(config): Promise<void> {
        if (this._currentDevice) {
            // todo: 清除上个主控的资源
            await this._currentDevice.dispose();
        }
        this._currentDevice = new DeviceTarget(this, this.vm, config)
        // todo: 刷新新主控支持的小模块
        // ARDUINO需要读取dtr/rts...等配置
        if (IS_ARDUINO_MODE && this._currentDevice.config.boardConfig) {
            // 读取boards.txt(已经创建主控, 说明link肯定连接上了)
            const [company, arch, name] = this._currentDevice.config.boardConfig.board.split(":")
            fs.readFile(path.join(getArduinoPath(), `packages/${company}/hardware/${arch}/${this._currentDevice.config.boardConfig.version}/boards.txt`))
                .then((content) => {
                    // 解析
                    this._setOptions = {}
                    // 旧的格式(Arduino <=1.8.x)
                    let reg = new RegExp(`${name}.*disableDTR=(true|false)`)
                    let ret = content.match(reg)
                    if (ret) {
                        this._setOptions.dtr = !(ret[1] === "true")
                    }
                    reg = new RegExp(`${name}.*disableRTS=(true|false)`)
                    ret = content.match(reg)
                    if (ret) {
                        this._setOptions.rts = !(ret[1] === "true")
                    }
                    // 新的格式(Arduino 2.x)
                    reg = new RegExp(`${name}.*serial.dtr=(off|on)`)
                    ret = content.match(reg)
                    if (ret) {
                        this._setOptions.dtr = ret[1] === "on";
                    }
                    reg = new RegExp(`${name}.*serial.rts=(off|on)`)
                    ret = content.match(reg)
                    if (ret) {
                        this._setOptions.rts = ret[1] === "on";
                    }
                    reg = new RegExp(`${name}.*serial.brk=(off|on)`)
                    ret = content.match(reg)
                    if (ret) {
                        this._setOptions.brk = ret[1] === "on";
                    }
                    reg = new RegExp(`${name}.*serial.cts=(off|on)`)
                    ret = content.match(reg)
                    if (ret) {
                        this._setOptions.cts = ret[1] === "on";
                    }
                    reg = new RegExp(`${name}.*serial.dsr=(off|on)`)
                    ret = content.match(reg)
                    if (ret) {
                        this._setOptions.dsr = ret[1] === "on";
                    }
                    console.log("this._setOptions", this._setOptions)
                })
                .catch((err) => {
                    console.log("read boards.txt error:", err)
                    this._setOptions = {}
                })
        } else {
            this._setOptions = {}
        }
    }

    // 删除主控
    async deleteDevice() {
        if (this._currentDevice) {
            // todo: 清除上个主控的资源
            await this._currentDevice.dispose();
        }
        this._currentDevice = undefined;
    }

    get currentDevice() {
        return this._currentDevice;
    }

    // 获取主控扩展对象(getInfo)
    getDeviceExtensionObj() {
        return this._currentDevice && this._currentDevice.getDeviceExtensionObj();
    }

    // 添加小模块
    addModule(extensionId, config) {
        this._modules[extensionId] = config;
    }

    // 重新加载小模块
    reloadModule(extensionId) {
        vm.extensionManager.deleteExtension(extensionId);
        vm.extensionManager.loadExtension(extensionId);
    }

    // 移除小模块
    removeModule(extensionId) {
        vm.extensionManager.deleteExtension(extensionId)
        delete this._modules[extensionId];
    }

    // 移除所有的硬件小模块(舞台扩展不移除)
    removeAllDeviceModules() {
        for (let id in this._modules) {
            let config = this._modules[id];
            // 是否是主控的小模块
            if (!config.isSupportStage) {
                this.removeModule(id);
            }
        }
    }

    // 切换新主板之后, 刷新当前的硬件小模块, 不支持新主板的移除(舞台扩展不刷新)
    refreshAllDeviceModules(newDeviceIdWithVersion) {
        let [newDeviceId, version] = newDeviceIdWithVersion.split('@');
        for (let id in this._modules) {
            let config = this._modules[id];
            // 是否是主控的小模块
            if (!config.isSupportStage) {
                // 判断是否支持新主板
                if (config.supportDevices && config.supportDevices[newDeviceId]) {
                    // 判断是否支持新主板的版本
                    if (checkFuzzyVersion(config.supportDevices[newDeviceId], version)) {
                        // 实时模式需要重新加载小模块
                        if (IS_SCRATCH_MODE) {
                            this.reloadModule(id);
                        }
                        continue
                    };
                }
                this.removeModule(id);
            }
        }
        return this.vm.extensionManager.refreshBlocks();
    }

    // 获取当前主控的id
    getCurrentDeviceId() {
        return this._currentDevice && this._currentDevice.config.deviceId || '';
    }

    getCurrentDeviceVersion() {
        return this._currentDevice && this._currentDevice.config.version || '';
    }

    // 获取当前主控的名称
    getCurrentDeviceName() {
        return this._currentDevice && this._currentDevice.config.name || '';
    }

    // 获取当前主控的作者
    getCurrentDeviceAuthor() {
        return this._currentDevice && this._currentDevice.config.author || '';
    }

    // 当前target是不是上传模式
    isArduinoMode() {
        return IS_ARDUINO_MODE;
    }

    // 当前target是不是实时模式
    isScratchMode(targetId?) {
        return IS_SCRATCH_MODE;
    }

    // 获取当前target的当前模式
    getCodeMode(targetId?) {
        return MINDPLUS_MODE;
    }

    /****************** scratch模式功能 **************/
    // 是否连接
    isConnected(): boolean {
        if (!this._currentDevice) return false
        return this._currentDevice.isScratchConnected();
    }

    // 连接
    connect() {
        if (!this._currentDevice) {
            return Promise.reject('未加载主控')
        }
        const port = this.getCurrentPort();
        if (!port) return Promise.reject('没有选择串口')
        // 先断开上一个连接
        return this.disconnect().then(() => this._currentDevice!.connectScratch(port));
    }

    // 断开连接
    disconnect() {
        if (!this._currentDevice) {
            return Promise.reject("未加载主控")
        }
        return this._currentDevice.disconnectScratch();
    }

    // 监听scratch连接状态
    listenScratchConnectStatus() {

    }

    /*****************串口 ************* */
    // 切换当前主控的串口
    changePort(port) {
        this._port = port;
        let promise: any = () => Promise.resolve()
        // 先断开连接
        if (IS_SCRATCH_MODE) {
            promise = () => this.disconnect();
        }
        if (IS_ARDUINO_MODE) {
            promise = () => this.closeSerial();
        }
        // 切换串口为空
        if (!port) return promise();
        let promise2: any = () => Promise.resolve()
        if (IS_SCRATCH_MODE) {
            promise2 = () => this.connect();
        }
        return promise().then(() => promise2());
    }

    // 获取当前串口信息
    getCurrentPort() {
        return this._port;
    }

    // 打开串口
    openSerial() {
        // 没有选择串口
        if (!this._port) return Promise.reject("没有选择串口")
        // 已经打开
        if (this._serial) return Promise.reject("串口已经打开")
        this._pushOutputMessage("\n");
        return new Promise((resolve, reject) => {
            this._serial = new Serialport(this._port, {
                baudRate: this._baudRate,
            })
            this._serial.on('data', (msg: string) => {
                // 添加到串口消息
                this._pushSerialMessage(msg.toString());
            })
            this._serial.on('error', (err: string) => {
                // 输出打印
                this._pushOutputMessage(err.toString());
                reject(err);
            })
            this._serial.on('open', (err) => {
                // 设置dtr, rts, ...
                if (Object.keys(this._setOptions).length > 0) {
                    console.log("serial.set", this._setOptions)
                    this._serial?.set(this._setOptions, () => {
                        if (this._onSerialStatusCallback) this._onSerialStatusCallback(true)
                        resolve(0);
                    })
                    return;
                }
                if (this._onSerialStatusCallback) this._onSerialStatusCallback(true)
                resolve(0);
            })
            this._serial.on('close', () => {
                this._serial = null;
                if (this._onSerialStatusCallback) this._onSerialStatusCallback(false)
            })
        })
    }

    // 关闭串口
    closeSerial() {
        return new Promise((resolve, reject) => {
            if (!this._serial) return resolve(0)
            this._serial.close((err) => {
                if (err) {
                    reject(err);
                } else {
                    this._serial = null;
                    if (this._onSerialStatusCallback) this._onSerialStatusCallback(false)
                    resolve(0);
                }
            })
        })
    }

    // 监听串口状态
    listenSerialStatus(callback: Function | null) {
        this._onSerialStatusCallback = callback;
        if (this._onSerialStatusCallback) this._onSerialStatusCallback(!!this._serial)
    }

    serialWrite(msg: string, callback?: CustomErrorCallback) {
        if (!this._serial) return callback && callback(new Error("serial is not open."));
        this._serial.write(msg, callback)
    }

    // 更新串口波特率
    updateSerialBaudRate(baudRate: number, callback?: CustomErrorCallback) {
        this._baudRate = baudRate;
        if (!this._serial?.isOpen) return callback && callback(null);
        // 如果串口已经打开, 更新波特率
        this._serial!.update({ baudRate }, callback)
    }

    getSerialBaudRate() {
        return this._baudRate;
    }

    /******************* 消息处理 **********/
    // 添加串口消息
    _pushSerialMessage(message: string) {
        // 如果消息超过500行, 销毁前100行
        if (this._serialMessage.length >= 1000) {
            this._serialMessage = this._serialMessage.slice(200);
        }
        this._serialMessage.push({
            time: getCurrentTime(),
            content: message
        });
        if (this._onSerialMessagesCallback) this._onSerialMessagesCallback(this._serialMessage);
    }

    // 清除串口消息
    clearSerialMessage() {
        this._serialMessage = [];
        if (this._onSerialMessagesCallback) this._onSerialMessagesCallback(this._serialMessage);
    }

    // 监听串口消息
    listenSerialMessage(callback: Function | null) {
        this._onSerialMessagesCallback = callback
        if (callback) callback(this._serialMessage)
    }

    // 添加输出信息
    _pushOutputMessage(message) {
        this._outputMessage += message;
        if (this._onOutputMessagesCallback) this._onOutputMessagesCallback(this._outputMessage);
    }

    // 清除输出信息
    clearOutputMessage() {
        this._outputMessage = "";
        if (this._onOutputMessagesCallback) this._onOutputMessagesCallback(this._outputMessage);
    }

    // 监听输出信息
    listenOutputMessage(callback: Function | null) {
        this._onOutputMessagesCallback = callback
        if (callback) callback(this._outputMessage)
    }


    /********************** arduino模式功能 *******************/
    // 烧录
    upload() {
        if (!this._currentDevice) {
            return Promise.reject('未加载主控')
        }
        let promise: any = Promise.resolve()
        // 上传之前, 断开连接
        if (IS_ARDUINO_MODE) {
            promise = this.closeSerial()
        }
        if (IS_SCRATCH_MODE) {
            promise = this.disconnect()
        }
        const port = this.getCurrentPort();
        if (!port) return Promise.reject('没有选择串口')
        return promise.then(() => this._currentDevice!.upload(port));
    }

    // 编译
    compile(code) {
        if (!this._currentDevice) return Promise.reject("cannot find device");
        // 显示输出打印框
        if (this._showOutputCallback) this._showOutputCallback();
        this.clearOutputMessage()
        this._pushOutputMessage("开始编译...\r\n")
        return this._currentDevice.compile(code);
    }

    // 编译后上传
    compileAndUpload(code) {
        if (!this._currentDevice) return Promise.reject("cannot find device");
        const port = this.getCurrentPort();
        if (!port) return Promise.reject('没有选择串口')
        // 显示输出打印框
        if (this._showOutputCallback) this._showOutputCallback();
        this.clearOutputMessage()
        this._pushOutputMessage("开始上传...\r\n")
        return this.compile(code).then(() => this.upload())
    }

    // 擦除
    erase() {
        if (!this._currentDevice) {
            return Promise.reject('未加载主控')
        }
        let promise: any = Promise.resolve()
        // 上传之前, 断开连接
        if (!IS_ARDUINO_MODE) return Promise.reject("not in Arduino")
        const port = this.getCurrentPort();
        if (!port) return Promise.reject('没有选择串口')
        // 显示输出打印框
        if (this._showOutputCallback) this._showOutputCallback();
        this.clearOutputMessage()
        this._pushOutputMessage("开始擦除...\r\n")
        return promise.then(() => this._currentDevice!.erase(port));
    }
}

function getCurrentTime() {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const milliseconds = String(now.getMilliseconds()).padStart(3, '0');

    return `${hours}:${minutes}:${seconds}.${milliseconds}`;
}
