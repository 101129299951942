import Blockly from '../../../lib/scratch-blocks'
import {
    checkBlockIsFunctionDefinition,
    checkBlockIsMain,
    extractCPPFunctionName,
    ProceduresGetVariablePrefix
} from "../util/util";
import {
    getBlockFuncByOpcode,
} from "../util/getBlockFunc";
import pinyinFunc from '../util/chinese-into-pinyin';
import {intl} from "../../../redux/intl/intlSlice";
import * as Shadow from "./shadow";
import { vm } from 'lib/scratch-vm';

type SetupInitCodeType = { id: string, code: string, priority: number }[]

class ArduinoGenerator extends Blockly.Generator {
    name: string;
    
    /** 运算符优先级 */
    ORDER_ATOMIC = 0;         // 0 "" ...
    ORDER_UNARY_POSTFIX = 1;  // expr++ expr-- () [] .
    ORDER_UNARY_PREFIX = 2;   // -expr !expr ~expr ++expr --expr
    ORDER_MULTIPLICATIVE = 3; // * / % ~/
    ORDER_ADDITIVE = 4;       // + -
    ORDER_SHIFT = 5;          // << >>
    ORDER_RELATIONAL = 6;     // >= > <= <
    ORDER_EQUALITY = 7;       // == != === !==
    ORDER_BITWISE_AND = 8;    // &
    ORDER_BITWISE_XOR = 9;    // ^
    ORDER_BITWISE_OR = 10;    // |
    ORDER_LOGICAL_AND = 11;   // &&
    ORDER_LOGICAL_OR = 12;    // ||
    ORDER_CONDITIONAL = 13;   // expr ? expr : expr
    ORDER_ASSIGNMENT = 14;    // = *= /= ~/= %= += -= <<= >>= &= ^= |=
    ORDER_NONE = 99;          // (...)
    /** 注释添加位置 */
    COMMENT_POS_FRONT = 0;    // 添加到代码前一行
    COMMENT_POS_BACK = 1;     // 添加到代码后一行
    COMMENT_POS_LEFT = 2;     // 添加到代码左边
    COMMENT_POS_RIGHT = 3;    // 添加到代码右边

    errPrompts: {[functionKey: string]: string} = {}
    helpPrompts: string[] = []
    codeLabel: string[] = []
    include: { [id: string]: string } = {}
    initCode: { [id: string]: string } = {}
    defineCode: { [id: string]: string } = {}
    variableList: { [id: string]: any } = {}
    variable: { [id: string]: { name: string, type: string } } = {}
    // funcDeclaration: Array<{ [id: string]: { type: string, code: string } }> = []
    constVar: { [id: string]: {type: string, data: string[], isArray: boolean} } = {}
    object: { [id: string]: string } = {}
    globalCode: { [id: string]: string } = {}
    setupCode: string[] = []
    setupInitCode: SetupInitCodeType = [] // setup 初始化代码

    loopCode: string[] = []
    function: {[functionKey: string]: string[]} = {} // 事件回调函数
    customFunction: {[functionKey: string]: string[]} = {} // 事件回调函数
    staticFunction: { [id: string]: string } = {} // 静态函数

    currentHeader: string = ''

    comment: { [id: string]: { comment: string[], position: number } } = {}
    // 多线程代码区
    threads: {[threadName: string]: {setupCode: string[], loopCode: string[]}} = {}

    // 缓存变量区域
    cache: {[key: string]: any} = {}

    constructor(name: string) {
        super(name);
        this.name = name;
        // 添加arduino保留关键字
        this.addReservedWords(
            'Blockly,' +  // In case JS is evaled in the current window.
            'setup,loop,if,else,for,switch,case,while,do,break,continue,return,goto,' +
            'define,include,HIGH,LOW,INPUT,OUTPUT,INPUT_PULLUP,true,false,integer,' +
            'constants,floating,point,void,boolean,char,unsigned,byte,int,word,long,' +
            'float,double,string,String,array,static,volatile,const,sizeof,pinMode,' +
            'digitalWrite,digitalRead,analogReference,analogRead,analogWrite,tone,' +
            'noTone,shiftOut,shitIn,pulseIn,millis,micros,delay,delayMicroseconds,' +
            'min,max,abs,constrain,map,pow,sqrt,sin,cos,tan,randomSeed,random,' +
            'lowByte,highByte,bitRead,bitWrite,bitSet,bitClear,bit,attachInterrupt,' +
            'detachInterrupt,interrupts,noInterrupts');
    }

    deInit(): void {
        this.include = {};
        this.constVar = {};
        this.function = {};
        this.staticFunction = {};
        this.customFunction = {};
        this.setupInitCode = [];
        this.defineCode = {};
        this.currentHeader = ''
        this.threads = {}
        this.comment = {}
        this.threadTopComment = {}
    }

    init(boradName: string) {
        this.errPrompts = {}; // 错误提示
        this.helpPrompts = []; // 帮助提示
        this.codeLabel = ["MindPlus", boradName]; // 顶部注释
        this.include = {}; // include 区域
        this.initCode = {}; // initCode 区域
        this.defineCode = {};//define 区域
        this.variable = {} //变量区域
        // this.funcDeclaration = [] // 函数声明区域
        this.constVar = {};  // 常量区域
        this.object = {};  // 声明的对象 区域
        this.globalCode = {};  // 全局代码 区域

        this.setupCode = []
        this.setupInitCode = []
        this.loopCode = [];

        this.function = {};  // 事件函数
        this.customFunction = {};  // 自定义函数
        this.staticFunction = {}; // 静态函数

        this.threads = {}
        this.comment = {}

        this.cache = {}
        this.threadTopComment = {}; // 多线程顶部注释
    }

    finish() {
        // 帮助/错误提示
        let _prompt = this.getPromptsCode();
        // 顶部注释
        let _codeLable = this.getLabelCode();
        // include
        let _include = this.getIncludeCode();
        // 初始化代码, 在include之后
        let _initCode = this.getInitCode();
        // define
        let _defineCode = this.getDefineCode();
        // 动态变量
        let _variable = this.getVariableCode();
        // 函数&子线程声明
        // let _funcDeclaration = this.getFuncDeclarationCode();

        // 静态常量
        let _constant = this.getConstCode();
        // 创建对象
        let _object = this.getObjectCode();
        // 全局代码位置
        let _globalCode = this.getGlobalCode();
        // setup
        let _setup = this.getSetupCode();
        // loop
        let _loop = this.getLoopCode();
        // 多线程
        let _threads = this.getThreadsCode();

        // 自定义函数
        let _customFunc = this.getCustomFuncCode();
        // 事件函数
        let _eventFunc = this.getEventFuncCode();
        // 静态函数
        let _constantFunc = this.getStaticFunctionCode();

        // 组合代码
        let _arduinoCode =
            _prompt +
            _codeLable +
            _include +
            _initCode+
            _defineCode +
            _variable +
            // _funcDeclaration +
            _constant +
            _object +
            _globalCode +
            _setup +
            _loop +
            _threads +
            _customFunc +
            _eventFunc +
            _constantFunc;
        _arduinoCode = _arduinoCode.replace(/^\s+\n/, '');
        _arduinoCode = _arduinoCode.replace(/\n\s+$/, '\n');
        _arduinoCode = _arduinoCode.replace(/[ \t]+\n/g, '\n');
        return _arduinoCode;
    }

    public workspaceToCode(workspace: any, boardName: string) {
        let code: string
        // 清空所有生成代码
        this.init(boardName)
        // 获取所有顶部block(非hat形状的block也可以是顶部block)
        const topBlocks: any[] = workspace.getTopBlocks(true);
        // 获取所有 hat block
        const hatBlocks = topBlocks.filter(item => item.startHat_)
        let hasMain = false;
        for (const item of hatBlocks) {
            this.threadName = ""
            // 1. [xxx主程序]block
            if (checkBlockIsMain(item.type)) {
                if (!hasMain) {
                    hasMain = true;
                    this.BlockToCodeOfMain(item);
                } else {
                    // 如果已有 [主程序]头, 添加报错提示
                    this.addPrompt(String(item), "main");
                }
                continue
            }

            // 2. 多线程事件头
            // !!!!! 含有 THREAD 命名的filed被认为是多线程事件头
            let threadName = item.getFieldValue('THREAD');
            if (threadName) {
                this.threadName = threadName;
                if (!this.threads[this.threadName]) this.threads[this.threadName] = {setupCode: [], loopCode: []}
                this.BlockToCodeOfMain(item);
                continue
            }

            // 3. 其他hat block
            this.BlockToCodeOfFunction(item)
        }
        // 拼接代码,格式调整
        code = this.finish()
        // 释放相关变量
        this.deInit()
        return code
    }

    
    BlockToCodeOfMain(blockTop) {
        this.currentHeader = "";
        let block = blockTop;
        // 开始遍历顶部block以下，control_loop以上的所有block，用于生成setup中的代码
        do {
            let code = this.blockToCode(block);
            if (code && code !== '') {
                if (typeof code === "object") {
                    code = code[0] + ";";
                }
                if (this.threadName) {
                    this.threads[this.threadName].setupCode.push(code);
                } else {
                    this.setupCode.push(code);
                }
            }
            // 这里的nextBlock是方形连接的block, 圆形/菱形无法获取
            block = block.getNextBlock();
        } while (block && (block.type !== "control_forever"));
        // control_loop内部的所有block，用于生成loop中的代码
        if (block && (block.type === "control_forever")) {
            // 第一个control_loop不生成代码
            block = block.getInputTargetBlock("SUBSTACK");
            while (block) {
                let code = this.blockToCode(block);
                if (code && code !== '') {
                    if (typeof code === "object") {
                        code = code[0] + ";";
                    }
                    if (this.threadName) {
                        this.threads[this.threadName].loopCode.push(code);
                    } else {
                        this.loopCode.push(code);
                    }
                }
                block = block.getNextBlock();
            }
        }

    }

    /**
     * 将工作区事件回调或者自定义函数block转化成代码
     * @param {!Blockly.Block} blockTop 当前顶部块
     */
    BlockToCodeOfFunction(blockTop) {
        let functionKey = String(blockTop);
        let temp = this.function;
        // 是否是自定义积木
        if (checkBlockIsFunctionDefinition(blockTop.type)) {
            temp = this.customFunction;
        }
        // 检查事件头是否重复
        if (temp[functionKey]) {
            // 添加错误提示, 并且该事件头不再生成代码
            this.addPrompt(functionKey, "function")
            return
        }

        temp[functionKey] = [];
        // 事件头生成代码
        let hatCode = this.blockToCode(blockTop);
        if (!hatCode) {
            console.error("hat block must return code:", blockTop.type)
            return;
        }
        let block = blockTop.getNextBlock();
        temp[functionKey].push(hatCode + " {");
        while (block) {
            let code = this.blockToCode(block);
            if (code && code !== '' && typeof code === "string") {
                temp[functionKey].push(code);
            }
            block = block.getNextBlock();
        }

        // 在合并代码时添加 "}", 避免格式问题
        // this.function[functionKey].push("}");
    }


    /**
     * 将block转为代码
     * @param block
     * @private
     */
    blockToCode(block: any) {
        if (!block || block.disabled) {
            return '';
        }
        // 获取用户自定义注释
        let commentUser = block.getCommentText();
        // 程序头不生成代码, 但需要生成注释
        if (block.startHat_ && (checkBlockIsMain(block.type) || this.threadName)) {
            if (commentUser && commentUser !== "") {
                this.addCommentTop(commentUser.split("\n"));
            }
            return '';
        }
        if (commentUser && commentUser !== "") {
            this.addComment(block.id, commentUser.split("\n"), this.COMMENT_POS_FRONT, false);
        }
        // 获取执行函数 func
        const func = getBlockFuncByOpcode(block.type)
        if (!func) {
            console.error(`block '${block.type}' 没有生成代码方法!!`)
            return '';
        }
        // 在生成代码之前, 先通过getParameter生成 菱形block/圆形block/statement 的代码, 并传递
        let code: string | [string, number] | [string, string] = func(this, block, this.getParameter(block)) || "";
        // 组合生成代码
        if (code === null) {
            return '';
        } else if (code instanceof Array) {
            if (this.comment[block.id] && block.parentBlock_) {
                this.addComment(block.parentBlock_.id, this.comment[block.id].comment, this.comment[block.id].position, false);
            }
            return code;
        } else {
            if (this.comment[block.id]) {
                switch (this.comment[block.id].position) {
                    case this.COMMENT_POS_FRONT:
                        if (code) {
                            if (block.startHat_) {
                                // 帽子block的注释不用缩进
                                code = "\n" + code
                                code = "// " + this.comment[block.id].comment.join("\n// ") + code;
                            } else {
                                code = "\n\t" + code
                                code = "// " + this.comment[block.id].comment.join("\n\t// ") + code;
                            }
                        }
                        break;
                    case this.COMMENT_POS_BACK:
                        code = code + "\n\t// " + this.comment[block.id].comment.join("\n\t");
                        break;
                    case this.COMMENT_POS_LEFT:
                        code = "/* " + this.comment[block.id].comment.join(" ") + " */ " + code;
                        break;
                    case this.COMMENT_POS_RIGHT:
                        code = code + " // " + this.comment[block.id].comment.join(" ");
                        break;
                    default:
                        if (code) {
                            if (block.startHat_) {
                                // 帽子block的注释不用缩进
                                code = "\n" + code
                            } else {
                                code = "\n\t" + code
                            }
                        }
                        code = "// " + this.comment[block.id].comment.join("\n\t") + code;
                        break;
                }
            }
            return code;
        }
    }

    /**
     * Escape character
     * if str is with \ or " add escape character \, such as \\, \"
     */
    escapeCharacter(str, regxArr, replaceArr) {
        let len = replaceArr.length;
        if (len === regxArr.length) {
            for (let i = 0; i < len; i++) {
                str = str.replace(regxArr[i], replaceArr[i]);
            }
        }
        return str;
    }

    transTextForVar(variable) {
        let id = variable;
        if (this.variableList && this.variableList[id]) return this.variableList[id].text;
        const regx = /[^a-zA-Z0-9_$]/g;
        //为汉字或者其他特殊符号
        if (regx.test(variable)) {
            let text = pinyinFunc(variable);
            text = text.replace(/;/g, "").replace(/&#x/g, "_");
            if (!isNaN(Number(text.slice(0, 1)))) {
                text = "_" + text;
            }
            variable = text;
        }
        if (!isNaN(Number(variable.slice(0, 1)))) {
            variable = "_" + variable;
        }
        variable = variable.trim().replace(/\s/g, "_");
        let flag = false;
        for (let index in this.variableList) {
            if (variable === this.variableList[index].text) {
                for (let i = 1; i < 100; i++) {
                    let newName = `${variable}${i}`;
                    flag = false;
                    for (let k in this.variableList) {
                        if (newName === this.variableList[k].text) {
                            flag = true;
                            break;
                        }
                    }
                    if (!flag) {
                        flag = true;
                        this.variableList[id] = {text: newName, num: i};
                        variable = newName;
                        break;
                    }
                }
            }
        }
        if (!flag) this.variableList[id] = {text: variable, num: 0};
        return variable;
    }

    // 返回输入参数的生成代码(菱形/圆形block, 下拉框/输入框...等各种shadow block)
    valueToCode(block: any, order: number) {
        if (isNaN(order)) {
            throw `Expecting valid order from block${block.type}`;
        }
        if (!block) {
            return '';
        }
        // 1. block.category_有值可以认为是 拖入的矩形/菱形/圆形block(自定义积木没有category_)
        if (block.category_ || block.type === 'procedures_call') {
            let currentCode: string | number = "";
            const tuple = this.blockToCode(block);
            if (tuple instanceof Array) {
                let code = tuple[0];
                const innerOrder = tuple[1] as number;
                if (isNaN(innerOrder)) {
                    throw `Expecting valid order from value block ${block.type}`;
                }
                if (code && order <= innerOrder) {
                    if (order === innerOrder && (order === 0 || order === 99)) {

                    } else {
                        code = '(' + code + ')';
                    }
                }
                currentCode = code;
            } else {
                currentCode = tuple;
            }
            let nextCode = this.valueToCode(block.getNextBlock(), this.ORDER_UNARY_POSTFIX);
            let symbol = (nextCode && nextCode !== '' && currentCode && currentCode !== '') ? '\n' : '';
            return currentCode + symbol + nextCode;
        } 
        // 2. 自定义积木的参数block
        if (block.type === "argument_reporter_string_number" ||
            block.type === "argument_reporter_boolean" ||
            block.type === "argument_reporter_number") {
            let prefix = ProceduresGetVariablePrefix(block.type);
            return  prefix + this.transTextForVar(String(block));
        }
        // 3. shadow block(如: 下拉框/输入框/点阵图...等等)
        return this.shadowToCode(block, block.type);
    }

    shadowToCode(block: any, type: string) {
        const func = Shadow[type];
        if (!func) {
            // throw new Error(`没有这个shadow block的生成代码函数: ${type}`)
            return block.inputList[0].fieldRow[0].value_ || "0";
        }
        return func(this, block);
    }

    /**
     * 获取block的参数
     * @param block
     * @private
     */
    getParameter(block: any): { [key: string]: { code: string, parType: string, codeType: string } } {
        let input: any = null;
        let parameter = {};
        let parameterCount = block.inputList.length;
        for (let i = 0; i < parameterCount; i++) {
            let parameterName = block.inputList[i].name;
            //参数为 _TEMP_COLLAPSED_INPUT ，是block折叠时的图标，不参与代码生成
            if (parameterName && parameterName !== "_TEMP_COLLAPSED_INPUT") {
                parameter[parameterName] = {};
                // // 程序块输入
                // if (block.inputList[i].type === Blockly.NEXT_STATEMENT) {
                //     parameter[parameterName].code = "";
                //     parameter[parameterName].parType = "";
                //     parameter[parameterName].codeType = "";
                // }
                parameter[parameterName] = {};
                parameter[parameterName].code = '';
                parameter[parameterName].parType = undefined;
                parameter[parameterName].codeType = undefined;
                // 圆形或菱形输入
                if (block.inputList[i].type === Blockly.INPUT_VALUE) {
                    if (block.inputList[i].connection.check_ && block.inputList[i].connection.check_.length === 1 && block.inputList[i].connection.check_[0] === "Boolean") {
                        // bool类型参数没有shadow block, 无法通过valueToCode获取block
                        parameter[parameterName].code = false;
                        parameter[parameterName].parType = "bool";
                        parameter[parameterName].codeType = "number";
                    }
                }
                input = block.getInputTargetBlock(parameterName);
                if (input && input.outputConnection && input.outputConnection.dfCheck_) {
                    parameter[parameterName].checkType = input.outputConnection.dfCheck_;
                }
                if (input) {
                    let type = input.type;
                    const valueCode = this.valueToCode(input, this.ORDER_UNARY_POSTFIX);
                    parameter[parameterName].code = valueCode;
                    parameter[parameterName].parType = type;
                    parameter[parameterName].codeType = typeof (valueCode);
                }
            }
            let fieldRowParameterCount = block.inputList[i].fieldRow.length;
            for (let j = 0; j < fieldRowParameterCount; j++) {
                parameterName = block.inputList[i].fieldRow[j].name;
                if (parameterName) {
                    input = block.getFieldValue(parameterName);
                    parameter[parameterName] = {};
                    parameter[parameterName].code = input;
                    parameter[parameterName].parType = "menu";
                    parameter[parameterName].codeType = typeof (input);
                }
            }
        }
        return parameter;
    }

    

    // 添加顶部错误提示
    addPrompt(key: string, type: "main"|"function") {
        if (this.errPrompts[key]) return;
        const multiMainErr = {
            id: "gui.mainHeader.err",
            description: 'Label for main header err prompt',
            defaultMessage: '!Error: The program header [%1] can only be used at the same time. Please delete the duplicated module.'
        };
        const useMultiThreadErr = {
            id: "gui.mainHeader.help",
            description: 'Label for main header help prompt',
            defaultMessage: '!Help Tip: If multiple programs need to be executed at the same time, please use the "multi-threading function" in "Extension", please refer to the help documentation.'
        };
        // const threadErr1text = {
        //     id: "gui.threadHeader.err1",
        //     description: 'Label for thread header err1 prompt',
        //     defaultMessage: '!Error: The program header [%1] cannot be used at the same time. They belong to different kits or board.'
        // };
        const multiFunctionErr = {
            id: "gui.threadHeader.err2",
            description: 'Label for thread header err2 prompt',
            defaultMessage: '!Error Tip: The event header [%1] can only be used at the same time. Please delete the duplicated module.'
        };
        if (type === "function") {
            this.errPrompts[key] = intl.formatMessage(multiFunctionErr).replace("%1", key);
        } else {
            this.errPrompts[key] = intl.formatMessage(multiMainErr).replace("%1", key);
            // todo: 如果该主板支持多线程, 提示使用多线程
            if (false) {
                this.addHelpPrompt(intl.formatMessage(useMultiThreadErr));
            }
        }
    }

    // 添加顶部帮助提示
    addHelpPrompt(message: string) {
        this.helpPrompts.push(message);
    }

    /****************************** 分区域 获取 code *****************************************/

    // getUserCustomFunction(id) {
    //     return this.userCustomFunction[id];
    // }

    getPromptsCode() {
        let code = "";
        if (Object.values(this.errPrompts).length > 0) {
            code = Object.values(this.errPrompts).join("\n") + "\n";
        }
        if (this.helpPrompts.length > 0) {
            code += this.helpPrompts.join("\n") + "\n";
        }
        return code;
    }

    prefixLines(text: string, prefix: string): string {
        return prefix + text.replace(/\n(.)/g, '\n' + prefix + '$1');
    }

    getLabelCode() {
        let labels: string[] = [];
        if (this.codeLabel.length > 0) {
            labels.push("/*!");
            for (let i = 0; i < this.codeLabel.length; i++) {
                labels.push(this.prefixLines(this.codeLabel[i], " * "));
            }
            labels.push(" */");
            return labels.join('\n') + '\n';
        } else {
            return "";
        }
    }

    getIncludeCode() {
        let code: string = "";
        let _codeArray: string[] = [];
        for (let id in this.include) {
            if (this.include[id]) {
                _codeArray.push(this.include[id]);
            }
        }
        if (_codeArray.length > 0) {
            _codeArray.sort((a, b) => {
                return a.length - b.length;
            })
            code = _codeArray.join("\n") + "\n";
        }
        return code;
    }

    getInitCode() {
        let code: string = "";
        let _InitCodeArray: string[] = [];
        for (let id in this.initCode) {
            if (this.initCode[id]) {
                _InitCodeArray.push(this.initCode[id]);
            }
        }
        if (_InitCodeArray.length > 0) {
            code = _InitCodeArray.join("\n") + "\n";
        }
        return code;
    }

    getDefineCode() {
        let code = Object.keys(this.defineCode).map(item => this.defineCode[item]);
        if (code.length) {
            code.unshift('');
            code.push('');
        }
        return code.join('\n');
    }

    /**
     * 变量格式化
     * @param typeNameLength
     * @param typeName
     * @param item
     * @param codeWidth
     * @private
     */
    variableAlign(typeNameLength: number, typeName: string, item: string[], codeWidth: number) {
        let typeNameStr = typeName;
        let code = "";
        for (let i = 0; i < (typeNameLength - typeName.length + 1); i++) {
            typeNameStr += " ";
        }
        for (let i = 0; i < item.length; i++) {
            typeNameStr = typeNameStr + item[i]
            if (i >= item.length - 1) {
                typeNameStr += ";\n";
                code += typeNameStr;
                break;
            } else {
                typeNameStr += ", ";
            }
            if (typeNameStr.length >= codeWidth) {
                code = code + typeNameStr + "\n";
                typeNameStr = "";
                for (let j = 0; j < typeNameLength + 1; j++) {
                    typeNameStr += " ";
                }
            }
        }
        return code;
    }

    /**
     * 获取变量区域code
     * @private
     */
    getVariableCode() {
        let numberArray: string[] = [];
        let stringArray: string[] = [];
        let listArray: string[] = [];
        let code = "";
        for (let id in this.variable) {
            if (this.variable[id] && this.variable[id].type) {
                let type = this.variable[id].type;
                if (type === 'number') {
                    numberArray.push(this.variable[id].name);
                } else if (type === 'string') {
                    stringArray.push(this.variable[id].name);
                } else if (type === 'list') {
                    listArray.push(this.variable[id].name);
                }
            }
        }
        let typeNameLength;
        if (stringArray.length > 0) {
            typeNameLength = "String".length;
        }
        if (numberArray.length > 0) {
            typeNameLength = "volatile float".length;
        }
        if (listArray.length > 0) {
            typeNameLength = "SimpleList<String>".length;
        }
        if (stringArray.length > 0) {
            code += this.variableAlign(typeNameLength, "String", stringArray, 80);
        }
        if (numberArray.length > 0) {
            code += this.variableAlign(typeNameLength, "volatile float", numberArray, 80);
        }
        if (listArray.length > 0) {
            code += this.variableAlign(typeNameLength, "SimpleList<String>", listArray, 80);
        }
        if (code !== "") {
            let message = intl.formatMessage({
                id: "gui.codeLabel.dynamicVariables",
                description: 'Code split label',
                defaultMessage: 'Dynamic variables'
            });
            code = "\n// " + message + "\n" + code + "\n";
        }
        return code;
    }

    getConstCode() {
        let code = "";
        let _codeArray: string[] = [];
        for (let name in this.constVar) {
            if (this.constVar[name]) {
                let type = this.constVar[name].type;
                this.constVar[name].data.map((item, index) => {
                    _codeArray.push(`const ${type} ${name}${index === 0? "":"_"+index}${this.constVar[name].isArray&&"[]"||""} = ${item};`);
                })
            }
        }
        if (_codeArray.length > 0) {
            code = _codeArray.join("\n") + "\n";
        }
        if (code !== "") {
            let message = intl.formatMessage({
                id: "gui.codeLabel.staticConstants",
                description: 'Code split label',
                defaultMessage: 'Static constants'
            });
            code = "// " + message + "\n" + code + "\n";
        }
        return code;
    }

    getObjectCode() {
        let code = Object.values(this.object).join("\n")
        if (code !== "") {
            let message = intl.formatMessage({
                id: "gui.codeLabel.createObject",
                description: 'Code split label',
                defaultMessage: 'Create an object'
            });
            code = "// " + message + "\n" + code + "\n";
        } 
        return code;
    }

    getGlobalCode() {
        let code = Object.values(this.globalCode).join("\n")
        return code + "\n";
    }

    getSetupCode() {
        let code = "void setup() {\t\n\n}\n";
        let _codeArray: string[] = [];
        // setupInitCode 排序
        this.setupInitCode
            .sort((a, b) =>  b.priority - a.priority)
            .map(item => {
                if (item.id && item.code) {
                    _codeArray.push(item.code);
                }
            })
        // setupCode
        _codeArray = _codeArray.concat(this.setupCode);
        if (_codeArray.length > 0) {
            code = "void setup() {\n\t" + _codeArray.join("\n\t") + "\n}\n";
        }
        let message = intl.formatMessage({
            id: "gui.codeLabel.mainProgramStart",
            description: 'Code split label',
            defaultMessage: 'Main program start'
        });
        return "\n\n// " + message + "\n" + code;
    }

    getLoopCode() {
        let code = "void loop() {\t\n\n}\n";
        if (this.loopCode.length > 0) {
            code = "void loop() {\n\t" + this.loopCode.join("\n\t") + "\n}\n\n";
        }
        return code;
    }

    getThreadsCode() {
        let code = "";
        let codeArray: string[] = [];
        for (let id in this.threads) {
            codeArray = codeArray.concat(this.threads[id].setupCode);
            // 多线程顶部注释
            let topComment = "";
            const topCommentArr = this.threadTopComment[id];
            if (topCommentArr && topCommentArr.length > 0) {
                topComment = "// " + topCommentArr.join("\n// ") + "\n"
            }
            let message = intl.formatMessage({
                id: `gui.codeLabel.SubthreadProgramStarts`,
                description: 'Code split label',
                defaultMessage: 'Subthread %1 program starts'
            }).replace("%1", id.replace(/[^\d]/g, ''));
            code += `// ${message}\n${topComment}void ${id}::setup() {\n\t${codeArray.join("\n\t")}\n}\nvoid ${id}::loop() {\n\t${this.threads[id].loopCode.join("\n\t")}\n}\n`
            codeArray = [];
        }
        if (code !== "") {
            code = "\n" + code + "\n";
        }
        return code;
    }

    getCustomFuncCode() {
        let code = "";
        for (let codeArr of Object.values(this.customFunction)) {
            code += codeArr.join("\n\t")
            code += "\n}\n"
        }
        if (code) {
            const message = intl.formatMessage({
                id: "gui.codeLabel.customFunction",
                description: 'Code split label',
                defaultMessage: 'Custom function'
            })
            code = `\n// ${message}\n` + code + "\n";
        }
        return code;
    }

    getEventFuncCode() {
        let code = "";
        for (let codeArr of Object.values(this.function)) {
            code += codeArr.join("\n\t")
            code += "\n}\n"
        }
        if (code) {
            const message = intl.formatMessage({
                id: "gui.codeLabel.eventCallbackFunction",
                description: 'Code split label',
                defaultMessage: 'Event callback function'
            })
            code = `\n// ${message}\n` + code + "\n";
        }
        return code;
        
    }

    getStaticFunctionCode() {
        let code = "";
        for (let id in this.staticFunction) {
            if (this.staticFunction[id]) {
                code += this.staticFunction[id] + "\n";
            }
        }
        if (code !== "") {
            let message = intl.formatMessage({
                id: "gui.codeLabel.staticFunctions",
                description: 'Code split label',
                defaultMessage: 'Static functions'
            });
            code = "\n// " + message + "\n" + code + "\n";
        }
        return code;
    }

    splitName(type: string) {
        let prefix, lastName, index = type.indexOf('.');
        if (index !== -1) {
            prefix = type.substring(0, index);
            lastName = type.substring(index + 1);
        } else {
            index = type.indexOf('_');
            prefix = type.substring(0, index);
            lastName = type.substring(index + 1)
        }

        return [prefix, lastName];
    }

    //format code
    formatCodeInBraces(code) {
        let arr = this.trimItem(code.split("\n"));
        let result = this.formatArrCode(arr);
        return result;
    }
    //trim arr item
    trimItem(arr: any) {
        let temp: any = [];
        for (let i = 0; i < arr.length; i++) {
            if (arr[i]) {
                temp.push(arr[i].trim());
            }
        }
        return temp;
    }
    //add suitable \t for item in array
    formatArrCode(arr) {
        let symbol = "\n\t";
        let temp = arr.slice(0);
        let len = temp.length;
        for (let i = 0; i < len; i++) {
            if ((temp[i].indexOf("{") !== -1) && (temp[i].trim()[temp[i].length - 1] === "{")) {
                symbol += "\t";
            }
            if ((temp[i].indexOf("}") !== -1) && (temp[i].length === 1)) {
                temp[i - 1] = temp[i - 1].slice(0, temp[i - 1].length - 1);
                symbol = symbol.slice(0, symbol.length - 1);
            }
            if (symbol === "\n\t") {
                temp[i] = temp[i] + "\n\t";
            } else {
                temp[i] = temp[i] + symbol;
            }
        }
        if (len > 0) {
            temp[len - 1] = temp[len - 1].replace(/(\s*$)/g, "");
        }
        return temp.join("");
    }

    /************************ 开放的接口 **********************/
    // 添加include代码
    // 用法1: addInclude("xxx.h") --> #include <xxx.h>, xxx.h既是id也是.h
    // 用法2: addInclude("xxx.h", "lib/xxx.h") --> #include "lib/xxx.h" , 并且会覆盖之前的#include <xxx.h>
    addInclude(id, code) {
        if (!id) return;
        // 只传递了一个参数
        if (code === undefined && !this.include[code]) {
            this.include[id] = `#include <${id}>`
            return;
        }
        this.include[id] = `#include "${code}"`
    }

    // 添加初始化代码(在include之后)
    addInitCode(id, code) {
        if (!id) return;
        // 添加 ;
        if (code.indexOf(';') === -1) {
            code = code + ';';
        }
        // 只传递了一个参数
        if (code === undefined && !this.initCode[code]) {
            this.initCode[id] = code;
            return;
        }
        this.initCode[id] = code;
    }

    // 添加全局对象
    addObject(id: string, code: string, coverage: boolean) {
        // 添加 ;
        if (code.indexOf(';') === -1) {
            code = code + ';';
        }
        if (coverage || !this.object[id]) {
            this.object[id] = code;
        }
    }

    // 添加变量
    addVariable(name: string, type: string) {
        if (!name || name === "") {
            return "";
        }
        switch (type) {
            case 'number':
                name = "mind_n_" + name;
                break;
            case 'string':
                name = "mind_s_" + name;
                break;
            case 'list':
                name = "mind_l_" + name;
                break;
            default:
                name = "mind_n_" + name;
                break;
        }
        if (!this.variable[name]) {
            this.variable[name] = {} as any
            this.variable[name].name = name;
            this.variable[name].type = type;
        }
        return this.variable[name].name;
    }

    // 添加静态函数
    addFunction(code: string, coverage: boolean = false) {
        // 解析出函数名
        let funcName = extractCPPFunctionName(code)
        if (!funcName) return;
        if (!this.staticFunction[funcName] || coverage) this.staticFunction[funcName] = code;
    }

    addEvent(id: string, type: string, name: string, args: string, coverage: boolean) {
        throw new Error("该方法已被废弃, 事件头代码请直接return")
    }

    addConstant() {
        throw new Error("该方法已被废弃, 请使用addConst")
    }

    pushArray(id, name, item, length, repeat, coverage) {
        throw new Error("该方法已被废弃, 二维数组已被拆分")
    }

    getArray(id, lineFeeds) {
        throw new Error("该方法已被废弃, 二维数组已被拆分")
    }

    // 添加数组变量, 如果name相同, content不同, name自动加后缀, 并返回新name
    // isArray为true, 在name后加数组括号: name[]
    // coverage为true, name相同, 直接覆盖
    addConst(name: string, type: string, content: string, isArray: boolean = true, coverage: boolean = false): string {
        // 去掉空格和分号
        content = content.replace(/[ ;]/g, "")
        if (!this.constVar[name]) this.constVar[name] = {type: type, data: [], isArray: isArray}
        // 如果覆盖
        if (coverage) {
            this.constVar[name].data[0] = content;
            return name;
        }

        let index = this.constVar[name].data.indexOf(content)
        // 没有找到
        if (index === -1) {
            this.constVar[name].data.push(content);
            index = this.constVar[name].data.length - 1;
        }
        if (index > 0) return name + "_" + index;
        return name
    }

    addSetupMain() {
        throw Error("addSetupMain 接口已废弃, 请使用addSetup")
    }

    addSetupMainTop() {
        throw Error("addSetupMainTop 接口已废弃, 请使用addSetup(..., ..., 9)")
    }

    // 添加setup的代码
    addSetup(id: string, code: string, priority: number = 0, coverage: boolean = false) {
        const codeObj = this.setupInitCode;
        const temp = codeObj.find(item => item.id === id)
        if (
            coverage || // 覆盖此id的代码
            temp === undefined // 此id还没有生成代码
        ) {
            if (temp) {
                temp.priority = priority;
                temp.code = code;
            } else {
                codeObj.push({
                    id: id,
                    code: code,
                    priority: priority,
                })
            }
        }
    }

    addSetupTop() {
        throw Error("addSetupTop 接口已废弃, 请使用addSetup(..., ..., 9)")
    }


    getVariableName(block, type) {
        let name = block.getField(type).text_;
        return this.transTextForVar(name);
    }

    addCode() {
        throw Error("addCode接口已废弃, 请直接return code")
    }

    addDefine(name: string, value: string, cover: boolean) {
        let _id = name.replace(/ /g, '');
        if (!this.defineCode[_id] || cover) {
            this.defineCode[_id] = `#define ${name} ${value}`;
        }
    }

    addComment(id: string, comment: any, position: number, coverage: boolean) {
        if (coverage || !this.comment[id]) {
            if (!this.comment[id]) {
                this.comment[id] = {} as any
                this.comment[id].comment = [];
            }
            this.comment[id].position = position;
        }
        if (typeof comment === "object") {
            this.comment[id].comment = this.comment[id].comment.concat(comment);
        } else if (typeof comment === "string") {
            this.comment[id].comment.push(comment);
        } else if (typeof comment === "number") {
            this.comment[id].comment.push(String(comment));
        }
        return true;
    }

    addCommentTop(comment: any) {
        // 多线程处理
        if (this.threadName) {
            if (!this.threadTopComment[this.threadName]) this.threadTopComment[this.threadName] = []
            if (typeof comment === "object") {
                this.threadTopComment[this.threadName] = this.threadTopComment[this.threadName].concat(comment);
            } else if (typeof comment === "string") {
                this.threadTopComment[this.threadName].push(comment);
            } else if (typeof comment === "number") {
                this.threadTopComment[this.threadName].push(String(comment));
            }
            return;
        }

        if (typeof comment === "object") {
            this.codeLabel = this.codeLabel.concat(comment);
        } else if (typeof comment === "string") {
            this.codeLabel.push(comment);
        } else if (typeof comment === "number") {
            this.codeLabel.push(String(comment));
        }
    }

    addErrorPrompt(message: string) {
        this.errPrompts[message] = message;
    }

    // 添加到全局代码区域
    addGlobalCode(id: string, code: string, coverage: boolean) {
        if (coverage || !this.globalCode[id]) {
            this.globalCode[id] = code;
        }
    }
}

export const arduinoGenerator = new ArduinoGenerator("arduino")
