import React from "react";
import styles from "./Gui.module.scss";
import MenuBar from "./menu-bar/MenuBar";
import BlockWrapper from "./block-wrapper/BlockWrapper";
import { useInit } from "./util/useInit";
import { AssetLib } from "./asset-lib/AssetLib";
import { DragLayer } from "./drag-layer/DragLayer";
import { useSelector } from "react-redux";
import { selectModals } from "../redux/modals/modalsSlice";
import { vm } from 'lib/scratch-vm'
import { selectProject } from "redux/project/projectSlice";
import Loader from "component/loader/loader";
import { CodeWrapper } from "./code-wrapper/CodeWrapper";
import { selectCode } from "redux/code/codeSlice";

const Gui = () => {
  const { isInit } = useInit();
  const {projectLoadingStatus} = useSelector(selectProject);
  const  {isReady} = useSelector(selectCode)
  const { connectionModal } = useSelector(selectModals);
  if (!isInit) return null;
  return (
    <div className={styles.mainContainer} dir={"ltr"}>
      {(projectLoadingStatus === "loading" || !isReady) && <Loader />}
      {/* TODO: 创建项目时，messageId应该不一致  gui.loader.creating*/}
      {(projectLoadingStatus === "creating" || !isReady) && <Loader />}
      <div className={styles.header}>
        <MenuBar />
      </div>
      <div className={styles.content}>
        <div className={styles.blockWrapper}>
          <BlockWrapper />
        </div>
        <CodeWrapper />
      </div>
      <AssetLib />
      <DragLayer />
    </div>
  );
};

export default Gui;
