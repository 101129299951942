import { FormattedMessage } from "react-intl";
import installDriverMp4 from "./videos/install-driver.mp4";
import microbitCalibrationp4 from "./videos/microbit-calibration.mp4";
//transform expression
import expression1 from "./transform-expression/expression1.gif";
import expression2 from "./transform-expression/expression2.gif";
import expression3 from "./transform-expression/expression3.gif";
import expression4 from "./transform-expression/expression4.gif";
import expression5 from "./transform-expression/expression5.gif";
import expression6 from "./transform-expression/expression6.gif";

//特色动图
//lot
import lot1 from "./lot/lot1.gif";
import lot2 from "./lot/lot2.gif";
import lot3 from "./lot/lot3.gif";

//microbit触摸表情
import microbitTouch1 from "./microbit-touch/touch1.gif";
import microbitTouch2 from "./microbit-touch/touch2.gif";
import {
  default as microbitTouch3,
  default as microbitTouch4,
} from "./microbit-touch/touch3.gif";

// video image
import calibrationThumb from "../decks/videos/calibration.png";
import installDriverThumb from "../decks/videos/installDriver.png";
const dataSource = {
  "install-driver": {
    name: (
      <FormattedMessage
        defaultMessage="Install Driver"
        description="Name for the 'Glide around' how-to"
        id="gui.howtos.install-driver.name"
      />
    ),
    img: installDriverThumb,
    steps: [
      {
        video: installDriverMp4,
      },
      {
        deckIds: ["install-driver", "transform-expression", "microbit-touch"],
      },
    ],
  },
  "transform-expression": {
    name: (
      <FormattedMessage
        defaultMessage="Smile or Cry"
        description="Name for the 'Run away' how-to"
        id="gui.howtos.transform-expression.name"
      />
    ),
    img: expression6,
    steps: [
      {
        title: (
          <FormattedMessage
            defaultMessage="Switch to upload execution page"
            description="Step name for 'Drag out a “go to random position” block' step"
            id="gui.howtos.transform-expression.step_1"
          />
        ),
        image: expression1,
      },
      {
        title: (
          <FormattedMessage
            defaultMessage="Switch product"
            description="Step name for 'Click the block to run it' step"
            id="gui.howtos.transform-expression.step_2"
          />
        ),
        image: expression2,
      },
      {
        title: (
          <FormattedMessage
            defaultMessage="Drag block 1"
            description="Step 3 title"
            id="gui.howtos.transform-expression.step_3"
          />
        ),
        image: expression3,
      },
      {
        title: (
          <FormattedMessage
            defaultMessage="Drag block 2"
            description="Step name for 'Add a “when this sprite clicked” block' step"
            id="gui.howtos.transform-expression.step_4"
          />
        ),
        image: expression4,
      },
      {
        title: (
          <FormattedMessage
            defaultMessage="Drag block 3"
            description="Step name for 'Click the sprite to run it' step"
            id="gui.howtos.transform-expression.step_5"
          />
        ),
        image: expression5,
      },
      {
        title: (
          <FormattedMessage
            defaultMessage="transform expression"
            description="Step name for 'Add a “start sound” block' step"
            id="gui.howtos.transform-expression.step_6"
          />
        ),
        image: expression6,
      },
      {
        deckIds: ["install-driver", "transform-expression", "microbit-touch"],
      },
    ],
  },
  "microbit-touch": {
    name: (
      <FormattedMessage
        defaultMessage="Press to Smile or Cry"
        description="Name for the 'Run away' how-to"
        id="gui.howtos.microbit-touch.name"
      />
    ),
    img: microbitTouch4,
    steps: [
      {
        title: (
          <FormattedMessage
            defaultMessage="Switch to upload execution page"
            description="Step name for 'Drag out a “go to random position” block' step"
            id="gui.howtos.microbit-touch.step_1"
          />
        ),
        image: microbitTouch1,
      },
      {
        title: (
          <FormattedMessage
            defaultMessage="Open the 'Sample program' under the 'Learning' menu and select 'Pin touch'"
            description="Step name for 'Click the block to run it' step"
            id="gui.howtos.microbit-touch.step_2"
          />
        ),
        image: microbitTouch2,
      },
      {
        title: (
          <FormattedMessage
            defaultMessage="Connect the corresponding COM port of the device and click 'Upload to Device'"
            description="Step 3 title"
            id="gui.howtos.microbit-touch.step_3"
          />
        ),
        image: microbitTouch3,
      },
      {
        title: (
          <FormattedMessage
            defaultMessage="Press different keys to show different expressions"
            description="Step 3 title"
            id="gui.howtos.microbit-touch.step_4"
          />
        ),
        image: microbitTouch4,
      },
      {
        deckIds: ["install-driver", "transform-expression", "lot"],
      },
    ],
  },
  lot: {
    name: (
      <FormattedMessage
        defaultMessage="IOT (Internet of Things)"
        description="Name for the 'Run away' how-to"
        id="gui.howtos.lot.name"
      />
    ),
    img: lot3,
    steps: [
      {
        title: (
          <FormattedMessage
            defaultMessage="Select the routine"
            description="Step name for 'Drag out a “go to random position” block' step"
            id="gui.howtos.lot.step_1"
          />
        ),
        image: lot1,
      },
      {
        title: (
          <FormattedMessage
            defaultMessage="Burning program"
            description="Step name for 'Click the block to run it' step"
            id="gui.howtos.lot.step_2"
          />
        ),
        image: lot2,
      },
      {
        title: (
          <FormattedMessage
            defaultMessage="Feature diagram"
            description="Step 3 title"
            id="gui.howtos.lot.step_3"
          />
        ),
        image: lot3,
      },
      {
        deckIds: ["install-driver", "transform-expression", "microbit-touch"],
      },
    ],
  },
  "microbit-calibration": {
    name: (
      <FormattedMessage
        defaultMessage="micro:bit Calibration"
        description="Name for the 'microbit-calibration' how-to"
        id="gui.howtos.microbit-calibration.name"
      />
    ),
    img: calibrationThumb,
    steps: [
      {
        video: microbitCalibrationp4,
      },
      {
        deckIds: ["install-driver", "transform-expression", "lot"],
      },
    ],
  },
};

export default dataSource
