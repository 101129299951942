import * as events from "events";
import SerialportWs, { SerialOpenOptions, SetOptions } from "./websocket/serialportWs";
import {strToBase64, base64ToStr} from 'util/base64Chinese'
import {commandWs} from "./websocket/commandWs";

export interface SerialInfo {
    path: string;
    manufacturer: string | undefined;
    serialNumber: string | undefined;
    pnpId: string | undefined;
    locationId: string | undefined;
    productId: string | undefined;
    vendorId: string | undefined;
    friendlyName: string | undefined;
}

const defaultOptions = {
    autoOpen: true,
    baudRate: 9600,
    dataBits: 8,
    parity: 'none',
    stopBits: 1
};

export type CustomErrorCallback = (err: Error | null) => void

export default class Serialport extends events.EventEmitter {
    private serialWs: SerialportWs;
    options: SerialOpenOptions = {};
    path: string;
    setOption: {rts: boolean, dtr: boolean}
    _serialIsCreated: boolean = false; 

    constructor(path: string, options: SerialOpenOptions = {}) {
        super()
        this.serialWs = new SerialportWs()
        this.serialWs.on("wsOpen", this._onWsOpen.bind(this))
        this.serialWs.on("open", ()=>this.emit("open"))
        this.serialWs.on("error", (...args)=>this.emit("error", ...args))
        this.serialWs.on("data", (...args)=>this.emit("data", ...args))
        this.serialWs.on("close", (...args)=>this.emit("close", ...args))
        this.options = Object.assign({}, defaultOptions, options);
        this.path = path;
        this.setOption = {dtr: false, rts: false};
    }

    // 串口扫描
    static list(): Promise<SerialInfo[]> {
        return commandWs.sendRemoteRequest("serialList", undefined) as any;
    }

    get isOpen(): boolean {
        return (this.serialWs.isOpen()) || false;
    }

    _onWsOpen() {
        // 创建serialport对象
        this.serialWs.newSerialport(this.path, this.options)
            .then(() => () => {
                this._serialIsCreated = true
                this.emit("serialCreated");
            })
    }

    // 等待串口对象被创建
    waitCreated() {
        if (this._serialIsCreated) {
            return Promise.resolve();
        } else {
            return new Promise<void>((resolve, reject) => {
                // todo: 超时?
                this.once("serialCreated", () => {
                    resolve();
                })
            })
        }
    }

    // 等待串口打开
    waitOpen() {
        if (this.isOpen) {
            return Promise.resolve();
        } else {
            return new Promise<void>((resolve, reject) => {
                // todo: 超时?
                this.once("open", () => {
                    resolve();
                })
            })
        }
    }

    // 打开串口
    open(callback?: CustomErrorCallback) {
        // 如果串口已经打开
        if (this.isOpen) {
            if (callback) callback(null);
            return;
        }
        const onOpen_ = () => {
            if (callback) callback(null);
            clearEvent();
        }

        const onError_ = (err) => {
            if (callback) callback(err);
            clearEvent();
        }

        const clearEvent = () => {
            this.removeListener("open", onOpen_)
            this.removeListener("error", onError_)
        }

        // 等待串口对象创建成功之后再open
        this.waitCreated().then(() => this.serialWs.openSerial())
        this.once('open', onOpen_)
        this.once('error', onError_)
    }

    set(options: SetOptions, callback?: CustomErrorCallback) {
        this.setOption = Object.assign({}, this.setOption, options);
        // 在串口未打开之前, 调用此方法, link会上报错误
        this.serialWs.setSerial(this.setOption)
            .then(() => {
                if (callback) callback(null)
            })
            .catch((err) => {
                if (callback) callback(err)
            })
        
    }

    update(options: {baudRate: number}, callback?: CustomErrorCallback) {
        this.serialWs.updateSerial(options)
            .then(() => {
                if (callback) callback(null)
            })
            .catch((err) => {
                if (callback) callback(err)
            })
    }

    close(callback?: CustomErrorCallback) {
        // 发送关闭串口命令之后, websocket会自动断开连接
        this.serialWs.closeSerial()
            .then(() => {
                if (callback) callback(null)
            })
            .catch((err) => {
                if (callback) callback(err)
            })
    }

    write(data: any, callback?: CustomErrorCallback) {
        this.serialWs.serialWrite(data)
            .then(() => {
                if (callback) callback(null)
            })
            .catch((err) => {
                if (callback) callback(err)
            })
    }
}
