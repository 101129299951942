// 各种shadow block的生成代码函数
// 从我们的使用习惯来看, shadow-block 是和 filed 一一对应的
// 我们在vm中定义block的arguments-type, 不是filed name, 而是与filed对应的shadow-block name

// 在mind-blocks中搜索 Blockly.Field.register , 可以获得所有的filed名称
// 在mind-blocks的blocks_common目录可以找到所有的shadow-block

// !!!!TODO: 异形block是在扩展内部生成代码, 还是在这里???

// 默认返回值
function defaultValue(input) {
    return input.inputList[0].fieldRow[0].value_ || "0";
}

// math
export function math_number(generator, input) {
    return input.inputList[0].fieldRow[0].text_ || 0;
}

export function math_whole_number(generator, input) {
    return math_number(generator, input)
}

export function math_positive_number(generator, input) {
    return math_number(generator, input)
}

export function math_integer(generator, input) {
    if (isNaN(Number(input))) return 0
    const fieldRow = input.inputList[0].fieldRow[0];
    const num = fieldRow.text_;
    if (num.indexOf('.') !== -1) {
        return num
    }
    let ret = Number(num);
    if (fieldRow.edge_ === 180) {
        ret = Math.abs(ret);
    }
    return ret
}

export function math_angle(generator, input) {
    return math_integer(generator, input)
}
// 旧版: math_range
export function math_slider(generator, input) {
    return math_integer(generator, input)
}


// color
export function colour_picker(generator, input) {
    let ret = input.colour_ || "#FF7D00";
    ret = ret.toUpperCase();
    ret = ret.replace("#", "0x")
    return ret;
}

export function colour_palette(generator, input) {
    return colour_picker(generator, input)
}


// matrix
export function matrix(generator, input) {
    return defaultValue(input);
}

export function matrix_icons(generator, input) {
    return input.inputList[0].fieldRow[0].matrix_;
}


// note
export function note(generator, input) {
    return defaultValue(input);
}


// piano
export function piano(generator, input) {
    return defaultValue(input);
}


// setting
export function settings(generator, input) {
    let ret = ""
    try {
        ret = JSON.parse(input.inputList[0].fieldRow[0].value_);
    } catch (e) {
        console.log('shadow block "settings" 生成代码错误: json解析失败', e);
    }
    return ret
}

export function form_settings(generator, input) {
    return settings(generator, input);
}

export function text_preview(generator, input) {
    return defaultValue(input);
}

export function img_preview(generator, input) {
    let value = JSON.parse(input.inputList[0].fieldRow[0].value_);
    let w = Number(value.imgWidth);
    let h = Number(value.imgHeight);
    let data = input.inputList[0].fieldRow[0].imageData;
    return { data: data, w: w, h: h };
}

export function img_setting(generator, input) {
    return defaultValue(input);
}

export function online_img_setting(generator, input) {
    return defaultValue(input);
}

export function dir_setting(generator, input) {
    return defaultValue(input);
}

export function textarea(generator, input) {
    let ret = input.inputList[0].fieldRow[0].value_;
    if (isNaN(Number(ret))) {
        ret = generator.escapeCharacter(ret, [/\\/g, /\"/g], ["\\\\", "\\\""]);
    }
    return '\"' + ret + '\"';
}

export function builtin_img(generator, input) {
    return defaultValue(input);
}

export function cameralist_menu(generator, input) {
    return defaultValue(input);
}

export function obloq_settings(generator, input) {
    return settings(generator, input);
}

export function mqtt_settings(generator, input) {
    return settings(generator, input);
}


// text
export function text(generator, input) {
    let ret = input.inputList[0].fieldRow[0].text_;
    if (isNaN(Number(input))) {
        ret = generator.escapeCharacter(ret, [/\\/g, /\"/g], ["\\\\", "\\\""]);
    }
    ret = '\"' + ret + '\"';
    return ret;
}

export function text_infrared_btns(generator, input) {
    let ret = input.inputList[0].fieldRow[0].text_;
    return String(ret);
}




