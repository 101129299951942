import DFIcon from "component/df-icon/DFIcon"
import { vm } from "lib/scratch-vm"
import { defineMessages, FormattedMessage, useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { changeToBlockCode, changeToCustomCode, selectCode } from "redux/code/codeSlice"
import styles from "./ControlBar.module.scss"
import BurnIcon from "./icon--writing-to-board.svg"

const messages = defineMessages({
    compile: {
        id: "gui.gui.compileOnly",
        default: "Compile"
    },
    compileAndUpload: {
        id: "gui.gui.compileAndUpload",
        default: "Compiled and upload"
    },
})

export const ControlBar = () => {
    const dispatch = useDispatch()
    const intl = useIntl();
    const {codeType, customCode, blockCode} = useSelector(selectCode)

    // 烧录
    const onUpload = () => {
        const code = codeType === "block" ? blockCode : customCode;
        // isStopScanSerial为true, 表示正在上传
        if (vm.runtime.isStopScanSerial) return
        // 停止扫描串口(leonardo在烧录时会切换串口)
        vm.runtime.isStopScanSerial = true;
        vm.runtime.deviceManager.compileAndUpload(code)
            .then(() => {
                vm.runtime.deviceManager._pushOutputMessage("\n上传成功\n")
            })
            .catch((err) => {
                vm.runtime.deviceManager._pushOutputMessage("\n" + err + "\n")
            })
            .finally(() => {
                // leonardo复位需要1s
                setTimeout(() => {
                    vm.runtime.isStopScanSerial = false;
                }, 1000);
            })
    }
    const onCompile = () => {
        const code = codeType === "block" ? blockCode : customCode;
        vm.runtime.deviceManager.compile(code)
            .then(() => {
                vm.runtime.deviceManager._pushOutputMessage("\n编译成功\n")
            })
            .catch((err) => {
                vm.runtime.deviceManager._pushOutputMessage("\n" + err + "\n")
            })
    }

    // 擦除
    const onErase  = () => {
        vm.runtime.deviceManager.erase()
            .then(() => {
                vm.runtime.deviceManager._pushOutputMessage("\n擦除成功\n")
            })
            .catch((err) => {
                vm.runtime.deviceManager._pushOutputMessage("\n擦除失败: " + err + "\n")
            })
    }

    // 是否显示擦除按钮
    const isShowErase = vm.runtime.deviceManager.currentDevice&&vm.runtime.deviceManager.currentDevice.isSupportErase();

    return (
        <div className={styles.controlBar}>
            <div className={styles.control}>
                <div className={styles.controlBarIItem} title={intl.formatMessage(messages.compile)}>
                    <div className={styles.controlBarIcon} onClick={onCompile} > 
                        <DFIcon name="compile" size={24}/>
                    </div>
                </div>
                <div className={styles.controlBarIItem} title={intl.formatMessage(messages.compileAndUpload)}>
                    <div className={styles.controlBarIcon} onClick={onUpload} > 
                        <DFIcon name="upload" size={24}/>
                    </div>
                </div>
                {isShowErase&&<div className={styles.controlBarIItem} title={"擦除Flash"}>
                    <div className={styles.controlBarIcon} onClick={onErase} > 
                        <DFIcon name="erase" size={24}/>
                    </div>
                </div>}
            </div>
            <div className={styles.codeSwitchWrapper}>
                <div className={styles.codeSwitch}>
                    <div 
                        className={`${styles.switchItem} ${codeType === "block" ? styles.selected : ''}`} 
                        onClick={() => dispatch(changeToBlockCode())}
                    >
                        自动生成
                    </div>
                    <div 
                        className={`${styles.switchItem} ${codeType === "custom" ? styles.selected : ''}`} 
                        onClick={() => dispatch(changeToCustomCode())}
                    >
                        手动编辑
                    </div>
                </div>
            </div>
        </div>
    )
}
