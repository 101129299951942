export function procedures_definition(generator, block, parameter) {
    // 获取函数名
    let customBlock = block.getInputTargetBlock("custom_block");
    let funcName;
    if(customBlock.procCode_.indexOf("%") !== -1){
        funcName = customBlock.procCode_.slice(0, customBlock.procCode_.indexOf("%")).trim();  
    }
    else{
        funcName = customBlock.procCode_;
    }

    // 获取参数
    let typeArrys = getFuncArgsTypeArray(customBlock.procCode_);
    let argArrys = customBlock.displayNames_.map((item, index) => {
        let prefix = getVariablePrefix(typeArrys[index]);
        item = prefix + generator.transTextForVar(item);
        return item;
    });
    return `void DF_${generator.transTextForVar(funcName)}(${typeArrys.map((item, index) => item + " " + argArrys[index]).join(", ")})`;
}

export function procedures_call(generator, block, parameter) {
    let funcName;
    if (block.procCode_.indexOf("%") !== -1) {
        funcName = block.procCode_.slice(0, block.procCode_.indexOf("%")).trim();
    }
    else {
        funcName = block.procCode_;
    }
    funcName = `DF_${generator.transTextForVar(funcName)}`;
    let part: string[] = [];
    for (let index in parameter) {
        if (parameter[index] && parameter[index].code !== "") {
            part.push(parameter[index].code);
        }
    }
    return `${funcName}(${part.join(", ")});`;
}
//func variable type arr
function getFuncArgsTypeArray(procCode_) {
    let temp: string[] = [];
    while (procCode_.indexOf("%") !== -1) {
        let index = procCode_.indexOf("%");
        if (procCode_[index + 1] === "s") {
            temp.push("String");
        }
        if (procCode_[index + 1] === "n") {
            temp.push("float");
        }
        if (procCode_[index + 1] === "b") {
            temp.push("bool");
        }
        procCode_ = procCode_.slice(index + 1);
    }
    return temp;
}
function getVariablePrefix (type) {
    let prefix = '';
    if (type === "argument_reporter_string_number" ||
       type === 'String') {
        prefix = 'mind_s_';
    } else if (type === "argument_reporter_number" ||
        type === 'float') {
        prefix = 'mind_n_';
    } else if (type === "argument_reporter_boolean" ||
        type === 'bool') {
        prefix = 'mind_b_';
    }
    return prefix;
}
