import ScratchBlocks from "scratch-blocks";
import { DataType } from "../data-type";

export const redefineNumberBlocks = () => {
    
    ScratchBlocks.Blocks['data_list_caculate'] = {
        init: function () {
            this.jsonInit({
                "type": "data_list_caculate",
                "message0": ScratchBlocks.Msg.OPERATORS_LIST_CACULATE,
                "args0": [
                    {
                        "type": "field_image",
                        "src": ScratchBlocks.mainWorkspace.options.pathToMedia + "iconnumber.svg",
                        "width": 24,
                        "height": 24
                    },
                    {
                        "type": "field_vertical_separator"
                    },
                    {
                        "type": "field_dropdown",
                        "name": "OPERATOR",
                        "options": [
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_LIST_SUM, 'sum of the numbers in the list'],
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_LIST_MIN, 'minimum value in list'],
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_LIST_MAX, 'maximum value in the list'],
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_LIST_AVERAGE, 'average in list'],
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_LIST_NUM, 'number of digits in the list'],
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_LIST_MODE, 'list mode'],
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_LIST_STDDEVIATION, 'standard deviation in the list'],
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_LIST_RANDOM, 'random item of list']
                        ]
                    },
                    {
                        "type": "input_value",
                        "name": "OPERAND1",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                    }
                ],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_data", "output_number"]
            });
        }
    };

    ScratchBlocks.Blocks['data_binstr_to_int'] = {
        init: function () {
            this.jsonInit({
                "type": "data_binstr_to_int",
                "message0": ScratchBlocks.Msg.OPERATORS_BINSTR_TO_INT,
                "args0": [
                    {
                        "type": "field_image",
                        "src": ScratchBlocks.mainWorkspace.options.pathToMedia + "iconnumber.svg",
                        "width": 24,
                        "height": 24
                    },
                    {
                        "type": "field_vertical_separator"
                    },
                    {
                        "type": "field_dropdown",
                        "name": "OPERATOR",
                        "options": [
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_INTTOSTR_BIN, 'binary'],
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_INTTOSTR_OCT, 'octal'],
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_INTTOSTR_HEX, 'hex']
                        ]
                    },
                    {
                        "type": "input_value",
                        "name": "OPERAND1",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                    },
                ],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_data", "output_number"]
            });
        }
    };

    ScratchBlocks.Blocks['data_ascii_to_int'] = {
        init: function () {
            this.jsonInit({
                "type": "data_ascii_to_int",
                "message0": ScratchBlocks.Msg.OPERATORS_ASCII_TO_INT,
                "args0": [
                    {
                        "type": "field_image",
                        "src": ScratchBlocks.mainWorkspace.options.pathToMedia + "iconnumber.svg",
                        "width": 24,
                        "height": 24
                    },
                    {
                        "type": "field_vertical_separator"
                    },
                    {
                        "type": "input_value",
                        "name": "OPERAND1",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                    },
                ],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_data", "output_number"]
            });
        }
    };

    ScratchBlocks.Blocks['data_str_to_int'] = {
        init: function () {
            this.jsonInit({
                "type": "data_str_to_int",
                "message0": ScratchBlocks.Msg.OPERATORS_STR_TO_INT,
                "args0": [
                    {
                        "type": "field_image",
                        "src": ScratchBlocks.mainWorkspace.options.pathToMedia + "iconnumber.svg",
                        "width": 24,
                        "height": 24
                    },
                    {
                        "type": "field_vertical_separator"
                    },
                    {
                        "type": "input_value",
                        "name": "OPERAND1",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                    },
                ],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_data", "output_number"]
            });
        }
    };

    ScratchBlocks.Blocks['data_hexstr_to_bin'] = {
        init: function () {
            this.jsonInit({
                "type": "data_hexstr_to_bin",
                "message0": ScratchBlocks.Msg.OPERATORS_HEXSTR_TO_BIN,
                "args0": [
                    {
                        "type": "field_image",
                        "src": ScratchBlocks.mainWorkspace.options.pathToMedia + "iconnumber.svg",
                        "width": 24,
                        "height": 24
                    },
                    {
                        "type": "field_vertical_separator"
                    },
                    {
                        "type": "input_value",
                        "name": "OPERAND1",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                    },
                ],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_data", "output_number"]
            });
        }
    };

    ScratchBlocks.Blocks['data_str_to_base64'] = {
        init: function () {
            this.jsonInit({
                "type": "data_str_to_base64",
                "message0": ScratchBlocks.Msg.OPERATORS_STR_TO_BASE64,
                "args0": [
                    {
                        "type": "field_image",
                        "src": ScratchBlocks.mainWorkspace.options.pathToMedia + "iconnumber.svg",
                        "width": 24,
                        "height": 24
                    },
                    {
                        "type": "field_vertical_separator"
                    },
                    {
                        "type": "input_value",
                        "name": "OPERAND1",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                    },
                ],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_data", "output_string"]
            });
        }
    };

    ScratchBlocks.Blocks['data_base64_to_byte'] = {
        init: function () {
            this.jsonInit({
                "type": "data_base64_to_byte",
                "message0": ScratchBlocks.Msg.OPERATORS_BASE64_TO_BYTE,
                "args0": [
                    {
                        "type": "field_image",
                        "src": ScratchBlocks.mainWorkspace.options.pathToMedia + "iconnumber.svg",
                        "width": 24,
                        "height": 24
                    },
                    {
                        "type": "field_vertical_separator"
                    },
                    {
                        "type": "input_value",
                        "name": "OPERAND1",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                    },
                ],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_data", "output_string"]
            });
        }
    };
}
