import makeToolboxXML from "lib/make-toolbox-xml";

export function getToolboxXML (vm: any) {
    // Use try/catch because this requires digging pretty deep into the VM
    // Code inside intentionally ignores several error situations (no stage, etc.)
    // Because they would get caught by this try/catch
    try {
        let {editingTarget: target, runtime} = vm;
        const stage = runtime.getTargetForStage();
        if (!stage) return null;
        if (!target) target = stage; // If no editingTarget, use the stage

        const dynamicBlocksXML = vm.runtime.getBlocksXML(target);
        return makeToolboxXML(false, target.id, dynamicBlocksXML);
    } catch(e) {
        console.log('e==', e)
        return null;
    }
}
