
export function data_variable(generator, block, parameter) {
    let name = generator.getVariableName(block, "VARIABLE");
    name = generator.addVariable(name, 'number');
    return [name, generator.ORDER_ATOMIC];
}

export function data_setvariableto(generator, block, parameter) {
    let name = generator.getVariableName(block, "VARIABLE");
    name = generator.addVariable(name, 'number');
    return `${name} = ${parameter.VALUE.code};`
}

export function data_changevariableby(generator, block, parameter) {
    let name = generator.getVariableName(block, "VARIABLE");
    name = generator.addVariable(name, 'number');
    if (Number(parameter.VALUE.code) < 0) {
        return (`${name} -= ${Math.abs(Number(parameter.VALUE.code))};`);
    } else {
        return (`${name} += ${parameter.VALUE.code};`);
    }
}

export function data_string(generator, block, parameter) {
    let name = generator.getVariableName(block, "STRING");
    name = generator.addVariable(name, 'string');
    return ([name, generator.ORDER_ATOMIC])
}

export function data_setStringto(generator, block, parameter) {
    let name = generator.getVariableName(block, "STRING");
    name = generator.addVariable(name, 'string');
    return (`${name} = ${parameter.VALUE.code};`);
}

export function data_stringStartWith(generator, block, parameter) {
    let name = generator.getVariableName(block, "STRING");
    name = generator.addVariable(name, 'string');
    return ([`${name}.startsWith(${parameter.VALUE.code})`, generator.ORDER_ATOMIC]);
}

export function data_listcontents(generator, block, parameter) {
    let name = generator.getVariableName(block, "LIST");
    name = generator.addVariable(name, 'list');
    generator.addInclude('SimpleList.h');
    return ([name, generator.ORDER_ATOMIC])
}

export function data_addtolist(generator, block, parameter) {
    let name = generator.getVariableName(block, "LIST");
    name = generator.addVariable(name, 'list');
    generator.addInclude('SimpleList.h');
    return (`${name}.push_back(${parameter.ITEM.code});`);
}

export function data_deleteoflist(generator, block, parameter) {
    let name = generator.getVariableName(block, "LIST");
    name = generator.addVariable(name, 'list');
    generator.addInclude('SimpleList.h');
    return (`${name}.remove(${parameter.INDEX.code}-1);`);
}

export function data_deletealloflist(generator, block, parameter) {
    let name = generator.getVariableName(block, "LIST");
    name = generator.addVariable(name, 'list');
    generator.addInclude('SimpleList.h');
    return (`${name}.clear();`);
}

export function data_insertatlist(generator, block, parameter) {
    let name = generator.getVariableName(block, "LIST");
    let index = parameter.INDEX.code < 1 ? 1 : parameter.INDEX.code;
    name = generator.addVariable(name, 'list');
    generator.addInclude('SimpleList.h');
    return (`${name}.insert(${index}-1, ${parameter.ITEM.code});`);
}

export function data_replaceitemoflist(generator, block, parameter) {
    let name = generator.getVariableName(block, "LIST");
    let index = parameter.INDEX.code < 1 ? 1 : parameter.INDEX.code;
    name = generator.addVariable(name, 'list');
    generator.addInclude('SimpleList.h');
    return (`${name}.replace(${index}-1, ${parameter.ITEM.code});`);
}

export function data_itemoflist(generator, block, parameter) {
    let name = generator.getVariableName(block, "LIST");
    let index = parameter.INDEX.code < 1 ? 1 : parameter.INDEX.code;
    name = generator.addVariable(name, 'list');
    generator.addInclude('SimpleList.h');
    return ([`${name}[${index}-1]`, generator.ORDER_ATOMIC]);
}

export function data_itemnumoflist(generator, block, parameter) {
    let name = generator.getVariableName(block, "LIST");
    name = generator.addVariable(name, 'list');
    generator.addInclude('SimpleList.h');
    return ([`${name}.indexOf(${parameter.ITEM.code})`, generator.ORDER_ATOMIC]);
}

export function data_lengthoflist(generator, block, parameter) {
    let name = generator.getVariableName(block, "LIST");
    name = generator.addVariable(name, 'list');
    generator.addInclude('SimpleList.h');
    return ([`${name}.size()`, generator.ORDER_ATOMIC]);
}

export function data_listcontainsitem(generator, block, parameter) {
    let name = generator.getVariableName(block, "LIST");
    name = generator.addVariable(name, 'list');
    generator.addInclude('SimpleList.h');
    return ([`${name}.contains(${parameter.ITEM.code})`, generator.ORDER_ATOMIC]);
}