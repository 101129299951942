import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import { selectTheme } from "redux/theme/themeSlice";
import './app.module.scss'
import { ConfigProvider } from 'antd';
import Gui from "./view/ArduinoGui";
import { selectProject } from 'redux/project/projectSlice';

// icon-font会使用
export const themeColor = {
  "base": "#f8ad30",
  "blue": "#3498db",
  "orange": "#fa8c16",
  "purple": "#E3C1F8",
  "red": "#C61075",
  "green": "#6BD9A9",
  "violet": "#443256",
  "eye-protect": "#ff4400"
}

function Scratch() {
  const ref = useRef<HTMLBodyElement | null>(null)
  const theme = useSelector(selectTheme)
  const { projectTitle } = useSelector(selectProject)

  // 将projectTitle设置到document.title
  useEffect(() => {
    document.title = projectTitle
  }, [projectTitle])

  useEffect(() => {
    const body = document.body as HTMLBodyElement
    if (body) {
      ref.current = body
      ref.current.dataset.theme = theme
    }
  }, [theme])
  useEffect(() => {
    window.addEventListener('error', (event) => { 
      console.log("global error", event)
      return true
    }, true)

    window.addEventListener('unhandledrejection', (event) => { 
      console.log("global unhandledrejection", event)
      return true
    }, true)
  }, [])
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: themeColor[theme],
        },
      }}
    >
      <Gui />

    </ConfigProvider>
  )
}

export default Scratch;

