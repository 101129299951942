import bindAll from "lodash.bindall";
import PropTypes from "prop-types";
import React from "react";
import { defineMessages, injectIntl } from "react-intl";
import VM from "scratch-vm";

import backdropLibraryContent from "lib/asset/backdrop/backdrops.json";
import backdropTags from "lib/asset/backdrop/backdrop-tags";
import LibraryComponent from "component/library/library.jsx";

const messages = defineMessages({
  libraryTitle: {
    id: "gui.extension.backdropLib",
    description: "Label for extension of the backdropLib",
    defaultMessage: "Backdrop Library",
  },
});

class BackdropLibrary extends React.Component {
  constructor(props) {
    super(props);
    bindAll(this, ["handleItemSelect"]);
  }
  handleItemSelect(item) {
    const vmBackdrop = {
      name: item.name,
      rotationCenterX: item.info[0] && item.info[0] / 2,
      rotationCenterY: item.info[1] && item.info[1] / 2,
      bitmapResolution: item.info.length > 2 ? item.info[2] : 1,
      skinId: null,
    };
    // Do not switch to stage, just add the backdrop
    this.props.vm.addBackdrop(item.md5, vmBackdrop);
  }
  render() {
    return (
      <LibraryComponent
        data={backdropLibraryContent}
        id="backdropLibrary"
        tags={backdropTags}
        title={this.props.intl.formatMessage(messages.libraryTitle)}
        onItemSelected={this.handleItemSelect}
        onRequestClose={this.props.onRequestClose}
      />
    );
  }
}

BackdropLibrary.propTypes = {
  onRequestClose: PropTypes.func,
  vm: PropTypes.instanceOf(VM).isRequired,
};

export default injectIntl(BackdropLibrary);
