import ScratchBlocks from "scratch-blocks";
import { DataType } from "../data-type";

export const redefineDataBlocks = () => {
    // 重新定义data_variable block，设置checkboxInFlyout为false
    ScratchBlocks.Blocks.data_variable.init = function () {
        this.jsonInit({
            "message0": ScratchBlocks.Msg.VARIABLE,
            "lastDummyAlign0": "CENTRE",
            "args0": [
                {
                    "type": "field_variable_getter",
                    "text": "",
                    "name": "VARIABLE",
                    "variableType": ""
                }
            ],
            "category": ScratchBlocks.Categories.data,
            "outputTypes": ["uint32_t", "int32_t", "float"],
            "checkboxInFlyout": false,
            "extensions": ["contextMenu_getVariableBlock", "colours_data", "output_string"]
        });
    }

    ScratchBlocks.Blocks.data_setvariableto.init = function () {
        this.jsonInit({
            "message0": ScratchBlocks.Msg.DATA_SETVARIABLETO,
            "args0": [
                {
                    "type": "field_variable",
                    "name": "VARIABLE"
                },
                {
                    "type": "input_value",
                    "name": "VALUE",
                    "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL]
                }
            ],
            "category": ScratchBlocks.Categories.data,
            "extensions": ["colours_data", "shape_statement"]
        });
    }

    ScratchBlocks.Blocks.data_changevariableby.init = function () {
        this.jsonInit({
            "message0": ScratchBlocks.Msg.DATA_CHANGEVARIABLEBY,
            "args0": [
                {
                    "type": "field_variable",
                    "name": "VARIABLE"
                },
                {
                    "type": "input_value",
                    "name": "VALUE",
                    "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL]
                }
            ],
            "category": ScratchBlocks.Categories.data,
            "extensions": ["colours_data", "shape_statement"]
        });
    }


    ScratchBlocks.Blocks.data_string.init = function () {
        this.jsonInit({
            "message0": ScratchBlocks.Msg.VARIABLE,
            "lastDummyAlign0": "CENTRE",
            "args0": [
                {
                    "type": "field_variable_getter",
                    "text": "",
                    "name": "STRING",
                    "variableTypes": ["string"]
                }
            ],
            "outputTypes": ["string"],
            "category": ScratchBlocks.Categories.data,
            "checkboxInFlyout": false,
            "extensions": ["contextMenu_getStringBlock", "colours_data_string", "output_string"],
        });
    }

    ScratchBlocks.Blocks.data_setStringto.init = function () {
        this.jsonInit({
            "message0": ScratchBlocks.Msg.SET_STRING_VARIABLE,
            "args0": [
                {
                    "type": "field_variable",
                    "name": "STRING",
                    "variableTypes": ["string"]
                },
                {
                    "type": "input_value",
                    "name": "VALUE",
                    "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.STRING]
                }
            ],
            "category": ScratchBlocks.Categories.data,
            "extensions": ["colours_data_string", "shape_statement"]
        });
    }

    ScratchBlocks.Blocks.data_stringStartWith.init = function () {
        this.jsonInit({
            "message0": ScratchBlocks.Msg.STRING_START_WITH,
            "args0": [
                {
                    "type": "field_variable",
                    "name": "STRING",
                    "variableTypes": ["string"]
                },
                {
                    "type": "input_value",
                    "name": "VALUE",
                    "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.STRING]
                }
            ],
            "outputTypes": [DataType.BOOL],
            "category": ScratchBlocks.Categories.data,
            "extensions": ["colours_data_string", "output_boolean"]
        });
    }


    ScratchBlocks.Blocks.data_listcontents.init = function () {
        this.jsonInit({
            "message0": ScratchBlocks.Msg.VARIABLE_LIST,
            "args0": [
                {
                    "type": "field_variable_getter",
                    "text": "",
                    "name": "LIST",
                    "variableType": ScratchBlocks.LIST_VARIABLE_TYPE
                }
            ],
            "outputTypes": [DataType.LIST],
            "category": ScratchBlocks.Categories.dataLists,
            "extensions": ["contextMenu_getListBlock", "colours_data_lists", "output_string"],
            "checkboxInFlyout": false
        });
    }

    ScratchBlocks.Blocks.data_addtolist.init = function () {
        this.jsonInit({
            "message0": ScratchBlocks.Msg.DATA_ADDTOLIST,
            "args0": [
                {
                    "type": "input_value",
                    "name": "ITEM",
                    "inputTypes": [DataType.STRING]
                },
                {
                    "type": "field_variable",
                    "name": "LIST",
                    "variableTypes": [ScratchBlocks.LIST_VARIABLE_TYPE]
                }
            ],
            "category": ScratchBlocks.Categories.dataLists,
            "extensions": ["colours_data_lists", "shape_statement"]
        });
    }

    ScratchBlocks.Blocks.data_deleteoflist.init = function () {
        this.jsonInit({
            "message0": ScratchBlocks.Msg.DATA_DELETEOFLIST,
            "args0": [
                {
                    "type": "input_value",
                    "name": "INDEX",
                    "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.BOOL]
                },
                {
                    "type": "field_variable",
                    "name": "LIST",
                    "variableTypes": [ScratchBlocks.LIST_VARIABLE_TYPE]
                }
            ],
            "category": ScratchBlocks.Categories.dataLists,
            "extensions": ["colours_data_lists", "shape_statement"]
        });
    }

    ScratchBlocks.Blocks.data_insertatlist.init = function () {
        this.jsonInit({
            "type": "data_insertatlist",
            "message0": ScratchBlocks.Msg.DATA_INSERTATLIST,
            "args0": [
                {
                    "type": "input_value",
                    "name": "ITEM",
                    "inputTypes": [DataType.STRING]
                },
                {
                    "type": "input_value",
                    "name": "INDEX",
                    "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.BOOL]
                },
                {
                    "type": "field_variable",
                    "name": "LIST",
                    "variableTypes": [ScratchBlocks.LIST_VARIABLE_TYPE]
                }
            ],
            "category": ScratchBlocks.Categories.dataLists,
            "extensions": ["colours_data_lists", "shape_statement"]
        });
    }

    ScratchBlocks.Blocks.data_replaceitemoflist.init = function () {
        this.jsonInit({
            "type": "data_replaceitemoflist",
            "message0": ScratchBlocks.Msg.DATA_REPLACEITEMOFLIST,
            "args0": [
                {
                    "type": "input_value",
                    "name": "INDEX",
                    "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.BOOL]
                },
                {
                    "type": "field_variable",
                    "name": "LIST",
                    "variableTypes": [ScratchBlocks.LIST_VARIABLE_TYPE]
                },
                {
                    "type": "input_value",
                    "name": "ITEM",
                    "inputTypes": [DataType.STRING]
                }
            ],
            "category": ScratchBlocks.Categories.dataLists,
            "extensions": ["colours_data_lists", "shape_statement"]
        });
    }

    ScratchBlocks.Blocks.data_itemoflist.init = function () {
        this.jsonInit({
            "message0": ScratchBlocks.Msg.DATA_ITEMOFLIST,
            "args0": [
                {
                    "type": "input_value",
                    "name": "INDEX",
                    "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.BOOL]
                },
                {
                    "type": "field_variable",
                    "name": "LIST",
                    "variableTypes": [ScratchBlocks.LIST_VARIABLE_TYPE]
                }
            ],
            "output": null,
            "outputTypes": [DataType.STRING],
            "category": ScratchBlocks.Categories.dataLists,
            "extensions": ["colours_data_lists"],
            "outputShape": ScratchBlocks.OUTPUT_SHAPE_ROUND
        });
    }


    ScratchBlocks.Blocks['data_itemnumoflist'] = {
        init: function () {
            this.jsonInit({
                "message0": ScratchBlocks.Msg.DATA_ITEMNUMOFLIST,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "ITEM",
                        "inputTypes": [DataType.STRING]
                    },
                    {
                        "type": "field_variable",
                        "name": "LIST",
                        "variableTypes": [ScratchBlocks.LIST_VARIABLE_TYPE]
                    }
                ],
                "output": null,
                "outputTypes": [DataType.UINT32_T],
                "category": ScratchBlocks.Categories.dataLists,
                "extensions": ["colours_data_lists"],
                "outputShape": ScratchBlocks.OUTPUT_SHAPE_ROUND
            });
        }
    };

    ScratchBlocks.Blocks['data_lengthoflist'] = {
        init: function () {
            this.jsonInit({
                "message0": ScratchBlocks.Msg.DATA_LENGTHOFLIST,
                "args0": [
                    {
                        "type": "field_variable",
                        "name": "LIST",
                        "variableTypes": [ScratchBlocks.LIST_VARIABLE_TYPE]
                    }
                ],
                "outputTypes": [DataType.UINT32_T],
                "category": ScratchBlocks.Categories.dataLists,
                "extensions": ["colours_data_lists", "output_number"]
            });
        }
    };

    ScratchBlocks.Blocks['data_listcontainsitem'] = {
        init: function () {
            this.jsonInit({
                "message0": ScratchBlocks.Msg.DATA_LISTCONTAINSITEM,
                "args0": [
                    {
                        "type": "field_variable",
                        "name": "LIST",
                        "variableTypes": [ScratchBlocks.LIST_VARIABLE_TYPE]
                    },
                    {
                        "type": "input_value",
                        "name": "ITEM",
                        "inputTypes": [DataType.STRING]
                    }
                ],
                "outputTypes": [DataType.BOOL],
                "category": ScratchBlocks.Categories.dataLists,
                "extensions": ["colours_data_lists", "output_boolean"]
            });
        }
    };

    ScratchBlocks.Blocks['argument_reporter_boolean'] = {
        init: function () {
            this.jsonInit({
                "message0": " %1",
                "args0": [
                    {
                        "type": "field_label_serializable",
                        "name": "VALUE",
                        "text": ""
                    }
                ],
                "outputTypes": [DataType.BOOL],
                "extensions": ["colours_more", "output_boolean"]
            });
        }
    };

    ScratchBlocks.Blocks['argument_reporter_string_number'] = {
        init: function () {
            this.jsonInit({
                "message0": " %1",
                "args0": [
                    {
                        "type": "field_label_serializable",
                        "name": "VALUE",
                        "text": ""
                    }
                ],
                "outputTypes": [DataType.STRING],
                "extensions": ["colours_more", "output_number", "output_string"]
            });
        }
    };

    ScratchBlocks.Blocks['argument_reporter_number'] = {
        init: function () {
            this.jsonInit({
                "message0": " %1",
                "args0": [
                    {
                        "type": "field_label_serializable",
                        "name": "VALUE",
                        "text": ""
                    }
                ],
                "outputTypes": [DataType.INT32_T, DataType.UINT32_T, DataType.FLOAT],
                "extensions": ["colours_more", "output_number", "output_string"]
            });
        }
    };
}
