export const keywords = [
    "HIGH",
    "LOW",
    "INPUT",
    "OUTPUT",
    "SERIAL",
    "DISPLAY",
    "PI",
    "HALF_PI",
    "TWO_PI",
    "LSBFIRST",
    "MSBFIRST",
    "CHANGE",
    "FALLING",
    "RISING",
    "false",
    "true",
    "null",
    "boolean",
    "byte",
    "char",
    "class",
    "default",
    "do",
    "double",
    "int",
    "long",
    "private",
    "protected",
    "public",
    "return",
    "short",
    "signed",
    "static",
    "switch",
    "throw",
    "try",
    "unsigned",
    "void",
    "abs",
    "acos",
    "+=",
    "+",
    "[]",
    "asin",
    "=",
    "atan",
    "atan2",
    "&",
    "|",
    "boolean",
    "byte",
    "case",
    "ceil",
    "char",
    "char",
    "class",
    ",",
    "//",
    "?:",
    "constrain",
    "cos",
    "{}",
    "--",
    "default",
    "delay",
    "delayMicroseconds",
    "/",
    "/**",
    ". ",
    "else",
    "==",
    "exp",
    "false",
    "float",
    "float",
    "floor",
    "for",
    "<",
    "<=",
    "HALF_PI",
    "if",
    "++",
    "!=",
    "int",
    "<<",
    "<",
    "<=",
    "log",
    "&&",
    "!",
    "||",
    "loop",
    "max",
    "millis",
    "min",
    "-",
    "%",
    "/*",
    "*",
    "new",
    "null",
    "()",
    "PI",
    "return",
    ">>",
    ";",
    "Serial",
    "Setup",
    "sin",
    "sq",
    "sqrt",
    "-=",
    "switch",
    "tan",
    "this",
    "true",
    "TWO_PI",
    "void",
    "while",
    "Serial",
    "begin",
    "read",
    "print",
    "write",
    "println",
    "available",
    "digitalWrite",
    "digitalRead",
    "pinMode",
    "analogRead",
    "analogWrite",
    "attachInterrupts",
    "detachInterrupts",
    "beginSerial",
    "serialWrite",
    "serialRead",
    "serialAvailable",
    "printString",
    "printInteger",
    "printByte",
    "printHex",
    "printOctal",
    "printBinary",
    "printNewline",
    "pulseIn",
    "shiftOut",
]