import { IS_SCRATCH_MODE } from 'config/config';
import { vm } from 'lib/scratch-vm';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { openBackdropTab, openCostumeTab, openSoundTab, selectTab, showBlockTab } from 'redux/tab/tabSlice';
import { selectTargets } from 'redux/tagets/targetsSlice';
import DFIcon from "../../../component/df-icon/DFIcon";
import styles from './Tabs.module.scss';

export const Tabs = () => {
    const dispatch = useDispatch();
    const { currentTab } = useSelector(selectTab)
    const { editingTarget } = useSelector(selectTargets)
    const targetIsStage = editingTarget === vm.runtime.getTargetForStage()?.id;
    // 打开造型tab
    const onCostumeButtonClick = () => {
        dispatch(openCostumeTab());
    }

    // 打开声音tab
    const onSoundButtonClick = () => {
        dispatch(openSoundTab());
    }

    // 打开背景tab
    const onBackdropButtonClick = () => {
        dispatch(openBackdropTab());
    }

    // 打开block tab
    const onBlockButtonClick = () => {
        dispatch(showBlockTab());
    }

    return (
        <div className={styles.container}>
            <div className={`${styles.tab} ${currentTab === 'block' ? styles.isSelected : ''}`}
                onClick={onBlockButtonClick}>
                {/*<img draggable={false} src={codeIcon}/>*/}
                <div className={styles.icon}>
                    <DFIcon name={'block'} size={24} />
                </div>
                <FormattedMessage
                    defaultMessage="Code"
                    description="Button to get to the code panel"
                    id="gui.gui.blocks"
                />
            </div>
            {IS_SCRATCH_MODE && (
                <div
                    className={`${styles.tab} ${(currentTab === 'costume' || currentTab === 'backdrop') ? styles.isSelected : ''}`}
                    onClick={targetIsStage ? onBackdropButtonClick : onCostumeButtonClick}>
                    {/*<img draggable={false} src={costumesIcon}/>*/}
                    <div className={styles.icon}>
                        <DFIcon name={'design'} size={24} />
                    </div>
                    {targetIsStage ? (
                        <FormattedMessage
                            defaultMessage="Backdrops"
                            description="Button to get to the backdrops panel"
                            id="gui.gui.backdropsTab"
                        />
                    ) : (
                        <FormattedMessage
                            defaultMessage="Costumes"
                            description="Button to get to the costumes panel"
                            id="gui.gui.costumesTab"
                        />
                    )}
                </div>
            )}
            {IS_SCRATCH_MODE && (
                <div className={`${styles.tab} ${currentTab === 'sound' ? styles.isSelected : ''}`}
                    onClick={onSoundButtonClick}>
                    {/*<img draggable={false} src={soundsIcon}/>*/}
                    <div className={styles.icon}>
                        <DFIcon name={'sounds'} size={24} />
                    </div>
                    <FormattedMessage
                        defaultMessage="Sounds"
                        description="Button to get to the sounds panel"
                        id="gui.gui.soundsTab"
                    />
                </div>
            )}
        </div>
    )
}
