import styles from "./CodeEditor.module.scss"
import Editor, { loader } from '@monaco-editor/react';
import { useDispatch, useSelector } from "react-redux";
import { selectCode, setCodeReady, setCustomCode } from "redux/code/codeSlice";
import { language as cppLanguage } from 'monaco-editor/esm/vs/basic-languages/cpp/cpp'
import { useEffect, useRef, useState } from "react";
import {keywords} from "./keywords"
// import * as monaco from 'monaco-editor'; 
// import * as monaco from 'monaco-editor/esm/vs/editor/editor.api.js';

loader.config({
    paths:{
        vs:'/static/monaco-editor/min/vs'
    }
})
// loader.config({monaco})

export const CodeEditor = () => {
    const { codeType, blockCode, customCode, isReady } = useSelector(selectCode)
    const dispatch = useDispatch()
    const editorRef = useRef<any>();

    const onCustomCodeChange = (value) => {
        if (codeType !== "custom") return;
        dispatch(setCustomCode(value))
    }

    const onMount = (editor, monaco) => {
        editorRef.current = editor;
        // vs编辑器准备就绪, 可以退出project-loading
        dispatch(setCodeReady())
        // 语法规则
        monaco.languages.setMonarchTokensProvider('cpp', cppLanguage);
        // 代码自动补全(设置关键字)
        monaco.languages.registerCompletionItemProvider('cpp', {
            provideCompletionItems: (model, position) => {
                const suggestions = [
                    ...keywords.map(k => {
                    return {
                    label: k,
                    kind: monaco.languages.CompletionItemKind.Keyword,
                    insertText: k,
                    };
                })
                ];
                return { suggestions: suggestions}
            }
        })
    }

    useEffect(() => {
        if (!editorRef.current || !isReady) return;
        editorRef.current.setValue(codeType === "block" ? blockCode : customCode)
    }, [codeType, blockCode, isReady])

    return (
        <div className={styles.codeEditor}>
            <Editor
                height={'100%'}
                theme="vs-light"
                // language="cpp"
                defaultLanguage="cpp"
                // value={currentCode}
                options={{
                    contextmenu: false, // 禁用右键菜单
                    acceptSuggestionOnEnter: "on",
                    readOnly: codeType === "block",
                    minimap: {
                        enabled: false,
                    },
                    scrollbar: {
                        verticalScrollbarSize: 0
                    },
                    automaticLayout: true,
                    scrollBeyondLastLine: false,
                    scrollPredominantAxis: false,
                    lineNumbers: 'on',
                    lineNumbersMinChars: 5,
                    disableLayerHinting: true,
                    suggestOnTriggerCharacters: true,
                }}
                onChange={onCustomCodeChange}
                // beforeMount={handleEditorWillMount}
                onMount={onMount}
            />
        </div>
    )
}