export function operator_add(generator, block, parameter) {
    let code = parameter.NUM1.code + " + " + parameter.NUM2.code;
    return ([code, generator.ORDER_ADDITIVE]);
}

export function operator_subtract(generator, block, parameter) {
    let code = parameter.NUM1.code + " - " + parameter.NUM2.code;
    return ([code, generator.ORDER_ADDITIVE]);
}

export function operator_multiply(generator, block, parameter) {
    let code = parameter.NUM1.code + " * " + parameter.NUM2.code;
    return ([code, generator.ORDER_ADDITIVE]);
}

export function operator_divide(generator, block, parameter) {
    let code = parameter.NUM1.code + " / " + parameter.NUM2.code;
    return ([code, generator.ORDER_ADDITIVE]);
}

export function operator_random(generator, block, parameter) {
    let code = `random(${parameter.FROM.code}, ${parameter.TO.code}+1)`;
    // generator.addSetup(`dfrobotRandomSeed`, "dfrobotRandomSeed();", 9);
    return ([code, generator.ORDER_UNARY_POSTFIX]);
}

export function operator_lt(generator, block, parameter) {
    let p1 = parameter.OPERAND1.code;
    let p2 = parameter.OPERAND2.code
    let par = getOpParameters(p1, p2, parameter.OPERAND1.parType, parameter.OPERAND2.parType);
    let code = par[0] + "<" + par[1];
    return ([code, generator.ORDER_RELATIONAL]);
}

export function operator_equals(generator, block, parameter) {
let p1 = parameter.OPERAND1.code;
let p2 = parameter.OPERAND2.code
let par = getOpParameters2(p1, p2, parameter.OPERAND1.parType, parameter.OPERAND2.parType);
let code = par[0] + "==" + par[1];
return ([code, generator.ORDER_EQUALITY]);
};

export function operator_lt_equals(generator, block, parameter) {
    let p1 = parameter.OPERAND1.code;
    let p2 = parameter.OPERAND2.code
    let par = getOpParameters(p1, p2, parameter.OPERAND1.parType, parameter.OPERAND2.parType);
    let code = par[0] + "<=" + par[1];
    return ([code, generator.ORDER_RELATIONAL]);
}

export function operator_gt(generator, block, parameter) {
    let p1 = parameter.OPERAND1.code;
    let p2 = parameter.OPERAND2.code
    let par = getOpParameters(p1, p2, parameter.OPERAND1.parType, parameter.OPERAND2.parType);
    let code = par[0] + ">" + par[1];
    return ([code, generator.ORDER_RELATIONAL]);
}

export function operator_gt_equals(generator, block, parameter) {
    let p1 = parameter.OPERAND1.code;
    let p2 = parameter.OPERAND2.code
    let par = getOpParameters(p1, p2, parameter.OPERAND1.parType, parameter.OPERAND2.parType);
    let code = par[0] + ">=" + par[1];
    return ([code, generator.ORDER_RELATIONAL]);
}

export function operator_and(generator, block, parameter) {
    let code = parameter.OPERAND1.code + " && " + parameter.OPERAND2.code;
    return ([code, generator.ORDER_LOGICAL_AND]);
}

export function operator_or(generator, block, parameter) {
    let code = parameter.OPERAND1.code + " || " + parameter.OPERAND2.code;
    return ([code, generator.ORDER_LOGICAL_OR]);
}

export function operator_not(generator, block, parameter) {
    let code = '!' + parameter.OPERAND.code + '';
    return ([code, generator.ORDER_UNARY_PREFIX]);
}

export function operator_join(generator, block, parameter) {
    let code = `String(${parameter.STRING1.code}) + String(${parameter.STRING2.code})`;
    return ([code, generator.ORDER_UNARY_POSTFIX]);
}

export function operator_letter_of(generator, block, parameter) {
    let code = `String(String(${parameter.STRING.code}).charAt(${parameter.LETTER.code}-1))`;
    return ([code, generator.ORDER_UNARY_POSTFIX]);
}

export function operator_length(generator, block, parameter) {
    let code = `String(${parameter.STRING.code}).length()`;
    return ([code, generator.ORDER_UNARY_POSTFIX]);
}

export function operator_contains(generator, block, parameter) {
    let code = `(String(${parameter.STRING1.code}).indexOf(String(${parameter.STRING2.code})) != -1)`;
    return ([code, generator.ORDER_UNARY_POSTFIX]);
}

export function operator_textsrobotseven(generator, block, parameter) {
    let str2 = parameter.STRING2.code;
    let str3 = parameter.STRING3.code;
    let title1 = parameter.LIST1.code; //forward+ reverse-
    let title2 = parameter.LIST2.code;
    let strEnd = parameter.STRING1.code;
    let code;
    generator.addInclude("DFString.h")
    if ((title1 === 'first') && (title2 === 'first')) {
        code = `dfstring.substring(${strEnd},0,${str2},0,${str3})`;
    } else if ((title1 === 'first') && (title2 === 'last')) {
        code = `dfstring.substring(${strEnd},0,${str2},1,${str3})`;
    } else if ((title1 === 'last') && (title2 === 'first')) {
        code = `dfstring.substring(${strEnd},1,${str2},0,${str3})`;
    } else {
        code = `dfstring.substring(${strEnd},1,${str2},1,${str3})`;
    }
    return ([code, generator.ORDER_UNARY_POSTFIX]);
}

export function operator_textsrobotsevenNew(generator, block, parameter) {
    generator.addInclude("DFString.h")
    let string1 = typeof(parameter.STRING1.code) == "number" ? `"${parameter.STRING1.code}"` : parameter.STRING1.code;
    let string2 = typeof(parameter.STRING2.code) == "number" ? `"${parameter.STRING2.code}"` : parameter.STRING2.code;
    let code;
    if (parameter.LIST1.code === 'first') {
        code = `dfstring.indexOf(String(${string1}), String(${string2}))`;
    } else {
        code = `dfstring.lastIndexOf(String(${string1}), String(${string2}))`;
    }
    return ([code, generator.ORDER_UNARY_POSTFIX]);
}

export function operator_mod(generator, block, parameter) {
    let code = `(int32_t(${parameter.NUM1.code})) % (int32_t(${parameter.NUM2.code}))`;
    return ([code, generator.ORDER_MULTIPLICATIVE]);
}

export function operator_round(generator, block, parameter) {
    let code = `round(${parameter.NUM.code})`;
    return ([code, generator.ORDER_UNARY_POSTFIX]);
}

export function operator_mathop(generator, block, parameter) {
    let operator = parameter.OPERATOR.code;
    let num = parameter.NUM.code;
    let args = getOpCode(operator, num, generator.ORDER_UNARY_POSTFIX, generator.ORDER_MULTIPLICATIVE);
    return ([args[0], args[1]]);
}

export function operator_stringToNum(generator, block, parameter) {
    let code;
    if (parameter.TYPE.code == 'Integer') {
        code = `String(${parameter.STRING.code}).toInt()`;
    } else {
        code = `String(${parameter.STRING.code}).toFloat()`;
    }
    return ([code, generator.ORDER_UNARY_POSTFIX]);
}

export function operator_numToASCII(generator, block, parameter) {
    let code = `String(char(${parameter.NUM.code}))`;
    return ([code, generator.ORDER_UNARY_POSTFIX]);
}

export function operator_stringToASCII(generator, block, parameter) {
    let code = "";
    if (parameter.STRING.parType !== "text") {
        code = `toascii(${parameter.STRING.code}.charAt(0))`;
    } else {
        if (parameter.STRING.codeType === "string") {
            parameter.STRING.code = parameter.STRING.code[1];
        } else {
            parameter.STRING.code = String(parameter.STRING.code)[0];
        }
        code = `toascii('${parameter.STRING.code}')`;
    }
    return ([code, generator.ORDER_UNARY_POSTFIX]);
}

export function operator_numToString(generator, block, parameter) {
    let code = `String(${parameter.NUM.code})`;
    return ([code, generator.ORDER_UNARY_POSTFIX]);
}

export function operator_map(generator, block, parameter) {
    var fromhigh = parameter.FROMHIGH.code;
    var fromlow = parameter.FROMLOW.code;
    var tohigh = parameter.TOHIGH.code;
    var tolow = parameter.TOLOW.code;
    var value = parameter.VALUE.code;
    let code = `map(${value}, ${fromlow}, ${fromhigh}, ${tolow}, ${tohigh})`;
    return ([code, generator.ORDER_UNARY_POSTFIX]);
}

export function operator_constrain(generator, block, parameter) {
    var max = parameter.MAX.code;
    var min = parameter.MIN.code;
    var value = parameter.VALUE.code;
    let code = `constrain(${value}, ${min}, ${max})`;
    return ([code, generator.ORDER_UNARY_POSTFIX]);
}

//根据运算符返回对应的代码
function getOpCode(op, num, order1, order2) {
    let args: string[] = [];
    switch (op) {
        case 'abs':
            args[0] = `abs(${num})`;
            args[1] = order1;
            break;
        case 'floor':
            args[0] = `floor(${num})`;
            args[1] = order1;
            break;
        case 'ceiling':
            args[0] = `ceil(${num})`;
            args[1] = order1;
            break;
        case 'sqrt':
            args[0] = `sqrt(${num})`;
            args[1] = order1;
            break;
        case 'sin':
            args[0] = `sin((float)${num} / 180 * PI)`;
            args[1] = order1;
            break;
        case 'cos':
            args[0] = `cos((float)${num} / 180 * PI)`;
            args[1] = order1;
            break;
        case 'tan':
            args[0] = `tan((float)${num} / 180 * PI)`;
            args[1] = order1;
            break;
        case 'asin':
            args[0] = `asin(${num}) / PI * 180`;
            args[1] = order2;
            break;
        case 'acos':
            args[0] = `acos(${num}) / PI * 180`;
            args[1] = order2;
            break;
        case 'atan':
            args[0] = `atan(${num}) / PI * 180`;
            args[1] = order2;
            break;
        case 'ln':
            args[0] = `log(${num})`;
            args[1] = order1;
            break;
        case 'log':
            args[0] = `log10(${num})`;
            args[1] = order2;
            break;
        case 'e ^':
            args[0] = `exp(${num})`;
            args[1] = order1;
            break;
        case '10 ^':
            args[0] = `pow(10, ${num})`;
            args[1] = order1;
            break;
        default:
            args[0] = `abs(${num})`;
            args[1] = order1;
            break;
    }
    return args;
}

function getOpParameters(p1, p2, type1, type2) {
    if (type1 === "text") {
        p1 = p1.substr(1, p1.length - 2);
        p1 = p1 === "" ? 0 : p1;
        if (!isNaN(Number(p1))) {
            p1 = Number(p1);
        } else {
            if (p1[0] === "\\" && p1[p1.length - 1] === "\"") {
                p1 = p1.substr(1, p1.length - 3) + "\"";
            } else {
                p1 = `"${p1}"`;
            }
        }
    }
    if (type2 === "text") {
        p2 = p2.substr(1, p2.length - 2);
        p2 = p2 === "" ? 0 : p2;
        if (!isNaN(Number(p2))) {
            p2 = Number(p2);
        } else {
            if (p2[0] === "\\" && p2[p2.length - 1] === "\"") {
                p2 = p2.substr(1, p2.length - 3) + "\"";
            } else {
                p2 = `"${p2}"`;
            }
        }
    }
    return [p1, p2];
}
function getOpParameters2(p1, p2, type1, type2) {
    if (type1 === "text") {
        p1 = p1.substr(1, p1.length - 2);
        p1 = p1 === "" ? 0 : p1;
        if (!isNaN(Number(p1))) {
            p1 = Number(p1);
        } else {
            if (p1[0] === "\\" && p1[p1.length - 1] === "\"") {
                p1 = p1.substr(1, p1.length - 3) + "\"";
            } else {
                p1 = `String("${p1}")`;
            }
        }
    }
    if (type2 === "text") {
        p2 = p2.substr(1, p2.length - 2);
        p2 = p2 === "" ? 0 : p2;
        if (!isNaN(Number(p2))) {
            p2 = Number(p2);
        } else {
            if (p2[0] === "\\" && p2[p2.length - 1] === "\"") {
                p2 = p2.substr(1, p2.length - 3) + "\"";
            } else {
                p2 = `String("${p2}")`;
            }
        }
    }
    return [p1, p2];
}