import {vm} from "lib/scratch-vm";


export const ProceduresGetVariablePrefix = (type: string) => {
    console.log("ProceduresGetVariablePrefix===", type)
    let prefix = '';
    if (type === "argument_reporter_string_number" ||
        type === 'String') {
        prefix = 'mind_s_';
    } else if (type === "argument_reporter_number" ||
        type === 'float') {
        prefix = 'mind_n_';
    } else if (type === "argument_reporter_boolean" ||
        type === 'bool') {
        prefix = 'mind_b_';
    }
    return prefix;
}
export const checkBlockIsMain = (opcode: string) => {
    // extensionId_start
    return opcode.indexOf('_start') === (opcode.length - '_start'.length);
}

export const checkBlockIsFunctionDefinition = (opcode: string) => {
    return opcode.endsWith('_functionDefinition') || opcode === 'procedures_definition'
}

// 解析c++代码的函数名
export function extractCPPFunctionName(code) {
    // 匹配C++函数定义的正则表达式
    const functionRegex = /(\w+[\*&\s]+)(\w+)\s*\([^)]*\)\s*{/
    let match = functionRegex.exec(code);

    if (match) {
        // match[2] 是函数名
        return match[2];
    }

    return null; // 如果没有匹配到函数名，则返回null
}

