import React, { useEffect } from "react";
import styles from "./Block.module.scss";
import ScratchBlocks from "lib/scratch-blocks";
import FunctionPrompt from "./function-prompt/FunctionPrompt";
import VariablePrompt from "./variable-prompt/VariablePrompt";
import { useToolboxUpdate } from "./util/useToolboxUpdate";
import { useWorkspace } from "./util/useWorkspace";
import { vm } from "lib/scratch-vm";
import { useBlockListener } from "./util/useBlockListener";
import { getBasePathConfig } from "service/path/base";
import path from "path";
import { useDispatch } from "react-redux";
import { openSoundTab } from "../../../redux/tab/tabSlice";
import {
    openConnectionModal,
    openSoundRecorderModal,
} from "../../../redux/modals/modalsSlice";
import { setConnectionModalExtensionId } from "../../../redux/connection-modal-extensionId/connectionModalExtensionIdSlice";
import { openSelectDirectoryDialog } from "service/link-adapter/util";
import { setColorPickingActive } from "redux/color-picking/colorPickingSlice";
import downloadBlob from "lib/download-blob";
import message from "component/df-message/Message";
import alertDialog from 'component/df-alert/DFAlert'
import { WorkspaceControl } from "./workspace-control/WorkspaceControl";

interface BlockProps {
    // displayNone?: boolean; // 隐藏, 不占位, 用于代码编辑区的缩放
    visibility: boolean; // 隐藏, 不占位, 用于block/声音/造型tab的切换
}

const Block = (props: BlockProps) => {
    const { workspaceRef } = useWorkspace();
    const dispatch = useDispatch();
    const { handleCategorySelected, requestToolboxUpdate } = useToolboxUpdate(workspaceRef);
    useBlockListener(workspaceRef);
    // get block's input params
    const getBlockArgInfo = (type, name) => {
        const arginfo = vm.runtime.getBlockArgInfo(type, name);
        if (arginfo) {
            return arginfo;
        }
        return null;
    };

    // 解决tab切换时, workspace不显示bug
    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, [props.visibility])

    // 监听workspace的刷新
    useEffect(() => {
        const onWorkspaceUpdate = (data) => {
            // When we change sprites, update the toolbox to have the new sprite's blocks
            const workspace = workspaceRef.current;
            // 刷新toolbox
            requestToolboxUpdate();

            // Remove and reattach the workspace listener (but allow flyout events)
            workspace.removeChangeListener(vm.blockListener);
            const dom = ScratchBlocks.Xml.textToDom(data.xml);
            try {
                ScratchBlocks.Xml.clearWorkspaceAndLoadFromXml(dom, workspace);
            } catch (error: any) {
                // The workspace is likely incomplete. What did update should be
                // functional.
                //
                // Instead of throwing the error, by logging it and continuing as
                // normal lets the other workspace update processes complete in the
                // gui and vm, which lets the vm run even if the workspace is
                // incomplete. Throwing the error would keep things like setting the
                // correct editing target from happening which can interfere with
                // some blocks and processes in the vm.
                if (error.message) {
                    error.message = `Workspace Update Error: ${error.message}`;
                }
                console.error(error);
            }
            workspace.addChangeListener(vm.blockListener);

            // Clear the undo state of the workspace since this is a
            // fresh workspace and we don't want any changes made to another sprites
            // workspace to be 'undone' here.
            workspace.clearUndo();
        };
        vm.on("workspaceUpdate", onWorkspaceUpdate);
        return () => {
            vm.removeListener("workspaceUpdate", onWorkspaceUpdate);
        };
    }, []);

    const openNewPage = (href) => {
        console.log("openNewPage", href);
        // const { shell } = require('electron');
        // shell.openExternal(href);
        //TODO: 离线版可直接通过 electron 打开浏览器
        window.open(href);
    };
    const handleOpenSoundRecorder = () => {
        //打开sound tab
        dispatch(openSoundTab());
        //打开声音录制modal
        dispatch(openSoundRecorderModal());
    };

    const handleConnectionModalStart = (id: string) => {
        //记录扩展id
        dispatch(setConnectionModalExtensionId(id));
        //打开连接设备modal
        dispatch(openConnectionModal());
    };
    const getPathWithHomedir = (path_: string) => {
        const BASE_PATH_CONFIG = getBasePathConfig();
        return path.join(BASE_PATH_CONFIG.HOMEDIR, path_);
    };

    const getVariableValue = (id: string) => {
        return vm.getVariableValueForCurTarget(id);
    };


    const openDirectoryDialog = (options: any) => {
        // TODO: 离线版可直接通过 electron 打开文件夹选择框
        return openSelectDirectoryDialog(options);
    };

    const onActivateColorPicker = (callback: Function) => {
        return dispatch(setColorPickingActive({ callback }));
    }

    const handleExportMatrix = (matrix: any) => {
        if (matrix.length === 0) {
            // message.error("没有要导出的自定义图案。请先点击右下角的保存按钮，保存自定义的图案后，再尝试导出");
            return
        }
        // 导出点阵
        const content = JSON.stringify(matrix);
        const blob = new Blob([content], { type: "text/plain;charset=utf-8" });
        downloadBlob("matrix.json", blob);
        // TODO: 离线版实现方式不一样
    }

    useEffect(() => {
        ScratchBlocks.Xml._cacheInputParamsInfo =
            vm.runtime._cacheInputParamsInfo.bind(vm.runtime);
        ScratchBlocks.openNewPage = openNewPage; //mind_blocks打开浏览器
        ScratchBlocks.Xml.getArgInfo = getBlockArgInfo;
        ScratchBlocks.getPathWithHomedir = getPathWithHomedir;
        //打开录音modal
        ScratchBlocks.recordSoundCallback = handleOpenSoundRecorder;
        ScratchBlocks.statusButtonCallback = handleConnectionModalStart;
        ScratchBlocks.getVariableValue = getVariableValue;
        // 使用electron 打开文件夹选择框
        ScratchBlocks.openDirectoryDialog = openDirectoryDialog;
        ScratchBlocks.exportMatrixs = handleExportMatrix; // 导出点阵
        ScratchBlocks.FieldColourSlider.activateEyedropper_ = onActivateColorPicker;
        ScratchBlocks.alertDialog = alertDialog;
        return () => {
            ScratchBlocks.Xml._cacheInputParamsInfo = null;
            ScratchBlocks.Xml.getArgInfo = null;
            ScratchBlocks.getPathWithHomedir = null;
            ScratchBlocks.openNewPage = null;
            ScratchBlocks.recordSoundCallback = null;
            ScratchBlocks.statusButtonCallback = null;
            ScratchBlocks.getVariableValue = null;
            ScratchBlocks.openDirectoryDialog = null;
            ScratchBlocks.FieldColourSlider.activateEyedropper_ = null;
            ScratchBlocks.exportMatrixs = null;
            ScratchBlocks.alertDialog = null;
        };
    }, []);

    // 自定义积木编辑弹窗关闭的处理函数
    const refreshWorkspaceOnFunctionPromptClosed = () => {
        console.log("refreshWorkspaceOnFunctionPromptClosed", workspaceRef.current);
        // 刷新toolbox
        workspaceRef.current?.refreshToolboxSelection_();
        // 滚动到自定义积木category
        // workspaceRef.current?.toolbox_.scrollToCategoryById('myBlocks');
    };
    return (
        <div
            className={styles.container}
            // style={{ display: props.visibility ? "flex" : "none" }}
            style={{
                position: props.visibility ? "relative" : "absolute",
                opacity: props.visibility ? 1 : 0,
                visibility: props.visibility ? "visible" : "hidden"
            }}
        >
            <div
                id={"blocklyDiv"}
                className={styles.blockContainer}
            // style={{ display: props.displayNone ? "none" : "block" }}
            ></div>
            <div className={styles.workspaceControlWrapper}>
                <WorkspaceControl workspaceRef={workspaceRef} />
            </div>
            <FunctionPrompt mainWorkspace={workspaceRef} />
            <VariablePrompt />
        </div>
    );
};

export default Block;


