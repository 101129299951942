export function control_wait(generator, block, parameter) {
  let time = parameter.DURATION.code;
  // 判断是否为十进制数
  let regx = /(^-?\d+$)|(^(-?\d+)(\.\d+)?$)/
  if (regx.test(time)) {
    time = Number(time) * 1000;
  } else {
    time = `${time} * 1000`;
  }
  return (`delay(${time});`);
}

export function control_repeat(generator, block, parameter) {
  let count = parameter.TIMES.code;
  let code;
  if (parameter.SUBSTACK.code) {
    code = 'for (int index = 0; ' + 'index < ' + count + '; ' + 'index++) {\n\t' + parameter.SUBSTACK.code + '\n\t}';
  } else {
    code = 'for (int index = 0; ' + 'index < ' + count + '; ' + 'index++) {\n\t' + '\n\t}';
  }
  return (generator.formatCodeInBraces(code));
}

export function control_if(generator, block, parameter) {
  let argument0 = parameter.CONDITION.code;
  argument0 = `${argument0}`;
  let code;
  if (parameter.SUBSTACK.code) {
    code = 'if (' + argument0 + ') {\n\t' + parameter.SUBSTACK.code + '\n\t}';
  } else {
    code = 'if (' + argument0 + ') {\n\t\n\t}';
  }
  return (generator.formatCodeInBraces(code));
}

export function control_if_else(generator, block, parameter) {
  let argument0 = parameter.CONDITION.code;
  argument0 = `${argument0}`;
  let code;
  if (parameter.SUBSTACK.code) {
    code = "if (" + argument0 + ") {\n" + parameter.SUBSTACK.code + "\n}\n";
  } else {
    code = "if (" + argument0 + ") {\n}\n";
  }
  if (parameter.SUBSTACK2.code) {
    code += "  else {\n" + parameter.SUBSTACK2.code + "\n}";
  } else {
    code += "  else {\n}";
  }
  return (generator.formatCodeInBraces(code));
}

// export function control_if_else_special(generator, block, parameter) {
//   debugger;
// 	let code;

// 	let len = block.count ? parseInt((block.count + '').replace(/e/, '')) : 1;
// 	let count_type = block.count ? (block.count + '').match(/e/) : false;


// 	for (let i = 0; i < len; i++) {
// 		let index = i > 0 ? i + 1 : '';
// 		let condition = parameter["CONDITION" + index] ? parameter["CONDITION" + index].code : false;
// 		let substack = parameter["SUBSTACK" + index] ? (parameter["SUBSTACK" + index].code || "") : "";
// 		// if(i == 0 && parameter["CONDITION"+index]){
// 		if (i == 0 || i < len - 1) {
// 			if (i == 0) {
// 				code = "if (" + condition + ") {\n" + substack + "\n}";
// 			} else {
// 				code += "\n else if (" + condition + ") {\n" + substack + "\n}";
// 			}
// 		}

// 		if ((i == 1 && len == 2) || (i >= 1 && i == len - 1)) {
// 			if (count_type) {
// 				// code += "\n else {\n" + substack + "\n}";
// 				code += "\n else if (" + condition + ") {\n" + substack + "\n}";
// 			} else {
// 				code += "\n else {\n" + substack + "\n}";
// 			}
// 		} else if (i == 1 && len == 1) {
// 			code += "\n";
// 		}
// 	}
// 	return (generator.formatCodeInBraces(code));
// }

export function control_wait_until(generator, block, parameter) {
  let argument0 = parameter.CONDITION.code;
  argument0 = `!${argument0}`;
  let code = 'while (' + argument0 + ') {' + '}';
  return (code);
}

export function control_repeat_until(generator, block, parameter) {
  let argument0 = parameter.CONDITION.code;
  argument0 = `!${argument0}`;
  let code;
  if (parameter.SUBSTACK.code) {
    code = 'while (' + argument0 + ') {\n\t' + parameter.SUBSTACK.code + '\n\t}';
  } else {
    code = 'while (' + argument0 + ') {\n\t' + '\n\t}';
  }
  return (generator.formatCodeInBraces(code));
}

export function control_forever(generator, block, parameter) {
  let code;
  if (parameter.SUBSTACK.code) {
    code = 'while (1) {\n\t' + parameter.SUBSTACK.code + '\n\t}';
  } else {
    code = 'while (1) {\n\t' + '\n\t}';
  }
  return (generator.formatCodeInBraces(code));
}

export function control_if_else_special(generator, block, parameter) {
  let code: string = "";
  let index = 0
  while (parameter[`IF${index}`]) {
    if (index === 0) {
      const ifCode = parameter[`IF${index}`].code ? parameter[`IF${index}`].code : "false";
      code = "if (" + ifCode  + ") {\n" + parameter[`DO${index}`].code + "\n}";
    } else {
      const elseIfCode = parameter[`IF${index}`].code ? parameter[`IF${index}`].code : "false";
      code += "\n else if (" + elseIfCode + ") {\n" + parameter[`DO${index}`].code + "\n}";
    }
    index++
  }
  if (parameter[`ELSE`]) {
    code += "\n else {\n" + parameter[`ELSE`].code + "\n}";
  }
  return (generator.formatCodeInBraces(code));
}
