export const DataType = {
    LIST: 'list',
    INT32_T: 'int32_t',
    UINT32_T: 'uint32_t',
    STRING: 'string',
    FLOAT: 'float',
    BOOL: 'bool',
    VOID: 'void',
    VOIDX: 'void*',
    COLOR: 'color',
    EXPRESSION: 'expression',
    PREVIEW: 'preview',
    SETTING: 'setting',
    NUMBER: ['int32_t', 'uint32_t', 'float', 'bool']
}
