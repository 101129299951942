import { base64ToStr, strToBase64 } from "util/base64Chinese";
import JSONRPC from "./jsonrpc";
import LinkWebSocket from "./link-websocket"

export interface SerialOpenOptions {
    baudRate?: number;
    stopBits?: number;
    autoOpen?: boolean;
    parity?: string;
    dataBits?: number
}

export interface SetOptions {
    brk?: boolean;
    cts?: boolean;
    dsr?: boolean;
    dtr?: boolean;
    rts?: boolean;
}

class SerialportWs extends JSONRPC {
    ws: LinkWebSocket
    constructor() {
        super();
        this.ws = new LinkWebSocket("Serialport");
        this.ws.setOnOpen(this._onWsOpen.bind(this));
        this.ws.setOnClose(this._onWsClose.bind(this));
        this.ws.setOnError(this._onWsError.bind(this));
        this.ws.setHandleMessage(this._handleMessage.bind(this));
        // 重写jsonrpc的方法
        this._sendMessage = this.ws.sendMessage.bind(this.ws);

        this.open()
    }

    sendRemoteRequest (method: string, params?: any) {
        if (!this.isOpen()) return Promise.reject("websocket not connected");
        return super.sendRemoteRequest(method, params)
    }

    sendRemoteNotification(method: string, params?: any) {
        if (!this.isOpen()) return
        super.sendRemoteNotification(method, params)
    }

    // 连接command ws
    open() {
        this.ws.open();
    }

    close() {
        this.ws.close();
    }

    isOpen() {
        return this.ws.isOpen();
    }

    _onWsOpen() {
        this.emit("wsOpen")
    }

    _onWsClose() {
        this.emit("close")
    }

    _onWsError(err) {
        this.emit("error", err.toString())
    }

    // 重写jsonrpc的方法
    // 这里是处理 下位机-->上位机 的request, 不是response
    didReceiveCall (method, params) {
        // 子进程的消息上报
        switch(method) {
            case "error": 
                this.emit('error', params);
                break;
            case "open": 
                this.emit('open');
                break;  
            case "close": 
                this.emit('close');
                break;  
            case "data": 
                this.emit('data', base64ToStr(params))
                break;  
        }
    }

    // 创建serialport对象
    newSerialport(path: string, options?: SerialOpenOptions) {
        return this.sendRemoteRequest("newSerialport", {
            port: path,
            baudRate: options?.baudRate || 9600,
            dataBits: options?.dataBits || 8,
            parity: options?.parity || 'parity',
            stopBits: options?.stopBits || 1,
        })
    }

    // 打开串口
    openSerial() {
        this.sendRemoteNotification("open", undefined)
    }

    // 关闭串口
    closeSerial() {
        return this.sendRemoteRequest("close", undefined)
    }

    // 设置 dtr rts
    setSerial(options: SetOptions) {
        return this.sendRemoteRequest("set", options)
    }

    // 更新串口设置
    updateSerial(options: {baudRate: number}) {
        return this.sendRemoteRequest("update", options)
    }

    // 串口写
    serialWrite(data: any) {
        let base64Code = strToBase64(data);
        return this.sendRemoteRequest("write", base64Code);
    }
}

export default SerialportWs