import ScratchBlocks from "scratch-blocks";
import { DataType } from "../data-type";

export const redefineOperatorBlocks = () => {
    ScratchBlocks.Blocks['operator_add'] = {
        init: function () {
            this.jsonInit({
                "message0": ScratchBlocks.Msg.OPERATORS_ADD,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "NUM1",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL]
                    },
                    {
                        "type": "input_value",
                        "name": "NUM2",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL]
                    }
                ],
                "outputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_operators", "output_number"]
            });
        }
    };

    ScratchBlocks.Blocks['operator_subtract'] = {
        init: function () {
            this.jsonInit({
                "message0": ScratchBlocks.Msg.OPERATORS_SUBTRACT,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "NUM1",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL]
                    },
                    {
                        "type": "input_value",
                        "name": "NUM2",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL]
                    }
                ],
                "outputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_operators", "output_number"]
            });
        }
    };

    ScratchBlocks.Blocks['operator_multiply'] = {
        init: function () {
            this.jsonInit({
                "message0": ScratchBlocks.Msg.OPERATORS_MULTIPLY,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "NUM1",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL]
                    },
                    {
                        "type": "input_value",
                        "name": "NUM2",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL]
                    }
                ],
                "outputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_operators", "output_number"]
            });
        }
    };

    ScratchBlocks.Blocks['operator_divide'] = {

        init: function () {
            this.jsonInit({
                "message0": ScratchBlocks.Msg.OPERATORS_DIVIDE,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "NUM1",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL]
                    },
                    {
                        "type": "input_value",
                        "name": "NUM2",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL]
                    }
                ],
                "outputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_operators", "output_number"]
            });
        }
    };

    ScratchBlocks.Blocks['operator_nums_caculate'] = {
        init: function () {
            this.jsonInit({
                "message0": ScratchBlocks.Msg.OPERATORS_NUMS_CACULATE,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "NUM1"
                    },
                    {
                        "type": "field_dropdown",
                        "name": "OPERATOR",
                        "options": [
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_SQUARE, '**'],
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_LOGIC_OR, '|'],
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_LOGIC_AND, '&'],
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_LOGIC_POWER, '^'],
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_LOGIC_LEFT, '<<'],
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_LOGIC_RIGHT, '>>']
                        ]
                    },
                    {
                        "type": "input_value",
                        "name": "NUM2"
                    }
                ],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_operators", "output_number"]
            });
        }
    };

    ScratchBlocks.Blocks['operator_random'] = {
        init: function () {
            this.jsonInit({
                "type": "operator_random",
                "message0": ScratchBlocks.Msg.OPERATORS_RANDOM,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "FROM",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL]
                    },
                    {
                        "type": "input_value",
                        "name": "TO",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL]
                    }
                ],
                "outputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_operators", "output_number"]
            });
        }
    };

    ScratchBlocks.Blocks['operator_lt'] = {
        init: function () {
            this.jsonInit({
                "type": "operator_lt",
                "message0": ScratchBlocks.Msg.OPERATORS_LT,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "OPERAND1",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                    },
                    {
                        "type": "input_value",
                        "name": "OPERAND2",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                    }
                ],
                "outputTypes": [DataType.BOOL],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_operators", "output_boolean"]
            });
        }
    };

    ScratchBlocks.Blocks['operator_lt_equals'] = {
        init: function () {
            this.jsonInit({
                "type": "operator_lt_equals",
                "message0": ScratchBlocks.Msg.OPERATORS_LT_EQUALS,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "OPERAND1",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                    },
                    {
                        "type": "input_value",
                        "name": "OPERAND2",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                    }
                ],
                "outputTypes": [DataType.BOOL],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_operators", "output_boolean"]
            });
        }
    };

    ScratchBlocks.Blocks['operator_equals'] = {
        init: function () {
            this.jsonInit({
                "type": "operator_equals",
                "message0": ScratchBlocks.Msg.OPERATORS_EQUALS,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "OPERAND1",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                    },
                    {
                        "type": "input_value",
                        "name": "OPERAND2",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                    }
                ],
                "outputTypes": [DataType.BOOL],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_operators", "output_boolean"]
            });
        }
    };

    ScratchBlocks.Blocks['operator_gt_equals'] = {
        init: function () {
            this.jsonInit({
                "type": "operator_gt_equals",
                "message0": ScratchBlocks.Msg.OPERATORS_GT_EQUALS,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "OPERAND1",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                    },
                    {
                        "type": "input_value",
                        "name": "OPERAND2",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                    }
                ],
                "outputTypes": [DataType.BOOL],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_operators", "output_boolean"]
            });
        }
    };

    ScratchBlocks.Blocks['operator_gt'] = {
        init: function () {
            this.jsonInit({
                "type": "operator_gt",
                "message0": ScratchBlocks.Msg.OPERATORS_GT,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "OPERAND1",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                    },
                    {
                        "type": "input_value",
                        "name": "OPERAND2",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                    }
                ],
                "outputTypes": [DataType.BOOL],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_operators", "output_boolean"]
            });
        }
    };

    ScratchBlocks.Blocks['operator_and'] = {
        init: function () {
            this.jsonInit({
                "message0": ScratchBlocks.Msg.OPERATORS_AND,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "OPERAND1",
                        "check": "Boolean",
                        "inputTypes": [DataType.BOOL]
                    },
                    {
                        "type": "input_value",
                        "name": "OPERAND2",
                        "check": "Boolean",
                        "inputTypes": [DataType.BOOL]
                    }],

                "outputTypes": [DataType.BOOL],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_operators", "output_boolean"]
            });
        }
    };

    ScratchBlocks.Blocks['operator_or'] = {
        init: function () {
            this.jsonInit({
                "message0": ScratchBlocks.Msg.OPERATORS_OR,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "OPERAND1",
                        "check": "Boolean",
                        "inputTypes": [DataType.BOOL],
                    },
                    {
                        "type": "input_value",
                        "name": "OPERAND2",
                        "check": "Boolean",
                        "inputTypes": [DataType.BOOL],
                    }
                ],
                "outputTypes": [DataType.BOOL],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_operators", "output_boolean"]
            });
        }
    };

    ScratchBlocks.Blocks['operator_not'] = {
        init: function () {
            this.jsonInit({
                "message0": ScratchBlocks.Msg.OPERATORS_NOT,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "OPERAND",
                        "check": "Boolean",
                        "inputTypes": [DataType.BOOL]
                    }
                ],
                "outputTypes": [DataType.BOOL],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_operators", "output_boolean"]
            });
        }
    };

    ScratchBlocks.Blocks['operator_value_is_true'] = {

        init: function () {
            this.jsonInit({
                "type": "operator_value_is_true",
                "message0": ScratchBlocks.Msg.OPERATORS_VALUE_IS_TRUE,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "OPERAND1",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                    },
                ],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_operators", "output_boolean"]
            });
        }
    };

    ScratchBlocks.Blocks['operator_value_type'] = {
        init: function () {
            this.jsonInit({
                "message0": ScratchBlocks.Msg.OPERATORS_VALUE_TYPE,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "OPERAND1",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                    },
                ],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_operators", "output_string"]
            });
        }
    };

    ScratchBlocks.Blocks['operator_value_type_is'] = {
        init: function () {
            this.jsonInit({
                "type": "operator_value_type_is",
                "message0": ScratchBlocks.Msg.OPERATORS_VALUE_TYPE_IS,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "OPERAND1",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                    },
                    {
                        "type": "field_dropdown",
                        "name": "LIST1",
                        "options": [
                            [ScratchBlocks.Msg.OPERATORS_VALUE_TYPE_INT, "int"],
                            [ScratchBlocks.Msg.OPERATORS_VALUE_TYPE_FLOAT, "float"],
                            [ScratchBlocks.Msg.OPERATORS_VALUE_TYPE_BOOL, "bool"],
                            [ScratchBlocks.Msg.OPERATORS_VALUE_TYPE_STR, "str"],
                            [ScratchBlocks.Msg.OPERATORS_VALUE_TYPE_LIST, "list"],
                            [ScratchBlocks.Msg.OPERATORS_VALUE_TYPE_TUPLE, "tuple"],
                            [ScratchBlocks.Msg.OPERATORS_VALUE_TYPE_SET, "set"],
                            [ScratchBlocks.Msg.OPERATORS_VALUE_TYPE_DICT, "dict"],
                            [ScratchBlocks.Msg.OPERATORS_VALUE_TYPE_BYTES, "bytes"],
                            [ScratchBlocks.Msg.OPERATORS_VALUE_TYPE_BYTEARRAY, "bytearray"],
                            [ScratchBlocks.Msg.OPERATORS_VALUE_TYPE_COMPLEX, "complex"],
                        ]
                    }
                ],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_operators", "output_boolean"]
            });
        }
    };

    ScratchBlocks.Blocks['operator_caculate_result'] = {
        init: function () {
            this.jsonInit({
                "type": "operator_caculate_result",
                "message0": ScratchBlocks.Msg.OPERATORS_CACULATE_RESULT,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "OPERAND1",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                    },
                ],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_operators", "output_string"]
            });
        }
    };

    ScratchBlocks.Blocks['operator_textsrobotseven'] = {
        init: function () {
            this.jsonInit(
                {
                    "type": "operator_textsrobotseven",
                    "message0": ScratchBlocks.Msg.OPERATORS_TEXTS_FOUR_NEW,
                    "args0": [
                        {
                            "type": "input_value",
                            "name": "STRING1",
                            "inputTypes": [DataType.STRING]
                        },
                        {
                            "type": "field_dropdown",
                            "name": "LIST1",
                            "options": [
                                [ScratchBlocks.Msg.OPERATORS_TEXTS_FOUR_ONE, "first"],
                                [ScratchBlocks.Msg.OPERATORS_TEXTS_FOUR_TWO, "last"]
                            ]
                        },
                        {
                            "type": "input_value",
                            "name": "STRING2",
                            "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL]
                        },
                        {
                            "type": "field_dropdown",
                            "name": "LIST2",
                            "options": [
                                [ScratchBlocks.Msg.OPERATORS_TEXTS_FOUR_THREE, "first"],
                                [ScratchBlocks.Msg.OPERATORS_TEXTS_FOUR_FOUR, "last"]
                            ]
                        },
                        {
                            "type": "input_value",
                            "name": "STRING3",
                            "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL]
                        }
                    ],
                    "outputTypes": [DataType.STRING],
                    "category": ScratchBlocks.Categories.operators,
                    "extensions": ["colours_operators", "output_number"]
                });
        }
    };

    ScratchBlocks.Blocks['operator_textsrobotsevenNew'] = {
        init: function () {
            this.jsonInit(
                {
                    "type": "operator_textsrobotsevenNew",
                    "message0": ScratchBlocks.Msg.OPERATORS_TEXTS_NEW_OP,
                    "args0": [
                        {
                            "type": "input_value",
                            "name": "STRING1",
                            "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                        },
                        {
                            "type": "input_value",
                            "name": "STRING2",
                            "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                        },
                        {
                            "type": "field_dropdown",
                            "name": "LIST1",
                            "options": [
                                [ScratchBlocks.Msg.OPERATORS_TEXTS_FOUR_F, "first"],
                                [ScratchBlocks.Msg.OPERATORS_TEXTS_FOUR_L, "last"]
                            ]
                        }
                    ],
                    "outputTypes": [DataType.UINT32_T],
                    "category": ScratchBlocks.Categories.operators,
                    "extensions": ["colours_operators", "output_string"]
                });
        }
    };

    ScratchBlocks.Blocks['operator_join'] = {
        init: function () {
            this.jsonInit({
                "type": "operator_join",
                "message0": ScratchBlocks.Msg.OPERATORS_JOIN,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "STRING1",
                        "inputTypes": [DataType.INT32_T, DataType.UINT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                    },
                    {
                        "type": "input_value",
                        "name": "STRING2",
                        "inputTypes": [DataType.INT32_T, DataType.UINT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                    }
                ],
                "outputTypes": [DataType.STRING],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_operators", "output_string"]
            });
        }
    };

    ScratchBlocks.Blocks['operator_letter_of'] = {

        init: function () {
            this.jsonInit({
                "type": "operator_letter_of",
                "message0": ScratchBlocks.Msg.OPERATORS_LETTEROF,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "LETTER",
                        "inputTypes": [DataType.INT32_T, DataType.UINT32_T, DataType.FLOAT, DataType.BOOL]
                    },
                    {
                        "type": "input_value",
                        "name": "STRING",
                        "inputTypes": [DataType.INT32_T, DataType.UINT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                    }
                ],

                "outputTypes": [DataType.STRING],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_operators", "output_string"]
            });
        }
    };

    ScratchBlocks.Blocks['operator_length'] = {

        init: function () {
            this.jsonInit({
                "type": "operator_length",
                "message0": ScratchBlocks.Msg.OPERATORS_LENGTH,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "STRING",
                        "inputTypes": [DataType.INT32_T, DataType.UINT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                    }
                ],
                "outputTypes": [DataType.UINT32_T],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_operators", "output_string"]
            });
        }
    };

    ScratchBlocks.Blocks['operator_contains'] = {
        init: function () {
            this.jsonInit({
                "type": "operator_contains",
                "message0": ScratchBlocks.Msg.OPERATORS_CONTAINS,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "STRING1",
                        "inputTypes": [DataType.INT32_T, DataType.UINT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                    },
                    {
                        "type": "input_value",
                        "name": "STRING2",
                        "inputTypes": [DataType.INT32_T, DataType.UINT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                    }
                ],
                "outputTypes": [DataType.BOOL],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_operators", "output_boolean"]
            });
        }
    };

    ScratchBlocks.Blocks['operator_mod'] = {
        init: function () {
            this.jsonInit({
                "message0": ScratchBlocks.Msg.OPERATORS_MOD,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "NUM1",
                        "inputTypes": [DataType.INT32_T, DataType.UINT32_T, DataType.FLOAT, DataType.BOOL]
                    },
                    {
                        "type": "input_value",
                        "name": "NUM2",
                        "inputTypes": [DataType.INT32_T, DataType.UINT32_T, DataType.FLOAT, DataType.BOOL]
                    }
                ],
                "outputTypes": [DataType.INT32_T, DataType.UINT32_T, DataType.FLOAT],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_operators", "output_number"]
            });
        }
    };

    ScratchBlocks.Blocks['operator_round'] = {
        init: function () {
            this.jsonInit({
                "message0": ScratchBlocks.Msg.OPERATORS_ROUND,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "NUM",
                        "inputTypes": [DataType.INT32_T, DataType.UINT32_T, DataType.FLOAT, DataType.BOOL],
                    }
                ],
                "outputTypes": [DataType.INT32_T, DataType.UINT32_T],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_operators", "output_number"]
            });
        }
    };

    ScratchBlocks.Blocks['operator_mathop'] = {
   
        init: function () {
            this.jsonInit({
                "message0": ScratchBlocks.Msg.OPERATORS_MATHOP,
                "args0": [
                    {
                        "type": "field_dropdown",
                        "name": "OPERATOR",
                        "options": [
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_ABS, 'abs'],
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_FLOOR, 'floor'],
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_CEILING, 'ceiling'],
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_SQRT, 'sqrt'],
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_SIN, 'sin'],
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_COS, 'cos'],
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_TAN, 'tan'],
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_ASIN, 'asin'],
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_ACOS, 'acos'],
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_ATAN, 'atan'],
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_LN, 'ln'],
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_LOG, 'log'],
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_EEXP, 'e ^'],
                            [ScratchBlocks.Msg.OPERATORS_MATHOP_10EXP, '10 ^']
                        ]
                    },
                    {
                        "type": "input_value",
                        "name": "NUM",
                        "inputTypes": [DataType.INT32_T, DataType.UINT32_T, DataType.FLOAT, DataType.BOOL],
                    }
                ],
                "outputTypes": [DataType.INT32_T, DataType.UINT32_T, DataType.FLOAT],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_operators", "output_number"]
            });
        }
    };

    ScratchBlocks.Blocks['operator_map'] = {
        init: function () {
            this.jsonInit(
                {
                    "message0": ScratchBlocks.Msg.OPERATORS_MAP,
                    "args0": [
                        {
                            "type": "input_value",
                            "name": "VALUE",
                            "inputTypes": [DataType.INT32_T, DataType.UINT32_T, DataType.FLOAT, DataType.BOOL],
                        },
                        {
                            "type": "input_value",
                            "name": "FROMLOW",
                            "inputTypes": [DataType.INT32_T, DataType.UINT32_T, DataType.FLOAT, DataType.BOOL],
                        },
                        {
                            "type": "input_value",
                            "name": "FROMHIGH",
                            "inputTypes": [DataType.INT32_T, DataType.UINT32_T, DataType.FLOAT, DataType.BOOL],
                        },
                        {
                            "type": "input_value",
                            "name": "TOLOW",
                            "inputTypes": [DataType.INT32_T, DataType.UINT32_T, DataType.FLOAT, DataType.BOOL],
                        },
                        {
                            "type": "input_value",
                            "name": "TOHIGH",
                            "inputTypes": [DataType.INT32_T, DataType.UINT32_T, DataType.FLOAT, DataType.BOOL],
                        }
                    ],
                    "outputTypes": [DataType.INT32_T, DataType.UINT32_T, DataType.FLOAT],
                    "category": ScratchBlocks.Categories.operators,
                    "extensions": ["colours_operators", "output_number"]
                });
        }
    };

    ScratchBlocks.Blocks['operator_constrain'] = {
        init: function () {
            this.jsonInit(
                {
                    "message0": ScratchBlocks.Msg.OPERATORS_CONSTRAIN,
                    "args0": [
                        {
                            "type": "input_value",
                            "name": "VALUE",
                            "inputTypes": [DataType.INT32_T, DataType.UINT32_T, DataType.FLOAT, DataType.BOOL]
                        },
                        {
                            "type": "input_value",
                            "name": "MIN",
                            "inputTypes": [DataType.INT32_T, DataType.UINT32_T, DataType.FLOAT, DataType.BOOL]
                        },
                        {
                            "type": "input_value",
                            "name": "MAX",
                            "inputTypes": [DataType.INT32_T, DataType.UINT32_T, DataType.FLOAT, DataType.BOOL]
                        }
                    ],
                    "outputTypes": [DataType.INT32_T, DataType.UINT32_T, DataType.FLOAT],
                    "category": ScratchBlocks.Categories.operators,
                    "extensions": ["colours_operators", "output_number"]
                });
        }
    };

    ScratchBlocks.Blocks['operator_stringToNum'] = {
        init: function () {
            this.jsonInit({
                "message0": ScratchBlocks.Msg.OPERATORS_STRINGTONUMBER,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "STRING",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL, DataType.STRING]
                    },
                    {
                        "type": "field_dropdown",
                        "name": "TYPE",
                        "options": [
                            [ScratchBlocks.Msg.INTEGER, 'Integer'],
                            [ScratchBlocks.Msg.DECIMAL, 'Decimal']
                        ]
                    }
                ],
                "outputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_operators", "output_number"]
            });
        }
    };

    ScratchBlocks.Blocks['operator_numToASCII'] = {
        init: function () {
            this.jsonInit({
                "message0": ScratchBlocks.Msg.OPERATORS_NUMTOASCII,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "NUM",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL]
                    }
                ],
                "outputTypes": [DataType.STRING],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_operators", "output_number"]
            });
        }
    };

    ScratchBlocks.Blocks['operator_stringToASCII'] = {
 
        init: function () {
            this.jsonInit({
                "type": "operator_stringToASCII",
                "message0": ScratchBlocks.Msg.OPERATORS_STRINGTOASCII,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "STRING",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.STRING]
                    }
                ],
                "outputTypes": [DataType.UINT32_T, DataType.INT32_T],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_operators", "output_number"]
            });
        }
    };
    
    ScratchBlocks.Blocks['operator_numToString'] = {

        init: function () {
            this.jsonInit({
                "message0": ScratchBlocks.Msg.OPERATORS_NUMTOSTRING,
                "args0": [
                    {
                        "type": "input_value",
                        "name": "NUM",
                        "inputTypes": [DataType.UINT32_T, DataType.INT32_T, DataType.FLOAT, DataType.BOOL]
                    }
                ],
                "outputTypes": [DataType.STRING],
                "category": ScratchBlocks.Categories.operators,
                "extensions": ["colours_operators", "output_string"]
            });
}
    };
}
