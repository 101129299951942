// @flow
import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";

import { defineMessages, useIntl } from "react-intl";
import styles from "./ProjectTitle.module.scss"
import { useEffect, useState } from "react";
import { selectProject, setProjectTitle } from 'redux/project/projectSlice';

const messages = defineMessages({
    projectTitlePlaceholder:
    {
        "id": "gui.gui.projectTitlePlaceholder",
        "description": "Placeholder for project title when blank",
        "defaultMessage": "Project title here"
    }
});

export const ProjectTitle = () => {
    const { projectTitle } = useSelector(selectProject)
    const dispatch = useDispatch()
    const [value, setValue] = useState(projectTitle)

    useEffect(() => {
        setValue(projectTitle)
    }, [projectTitle])
    
    const intl = useIntl()
    const handleUpdateProjectTitle = (value) => {
        dispatch(setProjectTitle(value))
    }
    const handleFlush = () => {
        const isNumeric = typeof value === 'number';
        const validatesNumeric = isNumeric ? !isNaN(value) : true;
        if (value !== null && validatesNumeric) {
            handleUpdateProjectTitle(isNumeric ? Number(value) : value);
        }
    }
    const handleChange = (e) => {
        setValue(e.target.value)
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleFlush();
            e.target.blur();
        }
    }
    return (
        <div className={styles.container}>
            <input
                className={styles.titleField}
                maxLength={100}
                placeholder={intl.formatMessage(messages.projectTitlePlaceholder)}
                tabIndex={0}
                type="text"
                value={value}
                onSubmit={handleUpdateProjectTitle}
                onBlur={handleFlush}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
            />
        </div>
    );
};
