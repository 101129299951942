import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store";

// 舞台全屏模式是在 modeSlice 中控制
interface CodeState {
    // size: 'small'|'large'|'medium'
    codeType: "block"|"custom" // 自动生成/手动编辑
    blockCode: string
    customCode: string
    isReady: boolean // 解决编辑器加载时间过长
}

const initialState: CodeState = {
    // size: 'medium'
    codeType: "block",
    blockCode: "",
    customCode: "",
    isReady: false,
}

export const codeSlice = createSlice({
    name: 'code',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // 切换到自动生成
        changeToBlockCode: (state) => {
            state.codeType = 'block';
        },
        // 切换到手动编辑
        changeToCustomCode: (state) => {
            state.codeType = 'custom';
        },
        // 设置自动生成代码
        setBlockCode: (state, action: PayloadAction<string>) => {
            state.blockCode = action.payload;
        },
        // 设置手动编辑代码
        setCustomCode: (state, action: PayloadAction<string>) => {
            state.customCode = action.payload;
        },
        setCodeReady: (state) => {
            state.isReady = true;
        },
    },
})

export const {
    changeToBlockCode, 
    changeToCustomCode,
    setBlockCode,
    setCustomCode,
    setCodeReady,
} = codeSlice.actions

export const selectCode= (state: RootState) => state.code

export default codeSlice.reducer
