import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store";

// 舞台全屏模式是在 modeSlice 中控制
interface StageSizeState {
    stageSize: 'small'|'large'
}

const initialState: StageSizeState = {
    stageSize: 'large'
}

export const stageSizeSlice = createSlice({
    name: 'stageSize',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setSmallStage: (state) => {
            state.stageSize = 'small';
        },
        setLargeStage: (state) => {
            state.stageSize = 'large';
        },
    },
})

export const {setLargeStage, setSmallStage} = stageSizeSlice.actions

export const selectStageSize= (state: RootState) => state.stageSize

export default stageSizeSlice.reducer
