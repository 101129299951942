import ScratchBlocks from 'lib/scratch-blocks';

const categorySeparator = '<sep gap="36"/>';

const blockSeparator = '<sep gap="36"/>'; // At default scale, about 28px

// 上传模式程序头
ScratchBlocks.Blocks['event_start'] = {
    init: function () {
        this.jsonInit({
            "id": "event_start",
            "message0": '当主程序启动',
            "category": ScratchBlocks.Categories.event,
            "extensions": ["colours_event", "shape_hat"]
        });
    }
};


const xmlEscape = function (unsafe) {
    return unsafe.replace(/[<>&'"]/g, c => {
        switch (c) {
            case '<': return '&lt;';
            case '>': return '&gt;';
            case '&': return '&amp;';
            case '\'': return '&apos;';
            case '"': return '&quot;';
        }
    });
};

const search = function (targetId) {
    return `
        <category
            name="搜索"
            id="search"
            colour="#3498db"
            targetId="${targetId}"
            secondaryColour="#67b2e4"
            >
        </category>
    `
}

const control = function ({ isInitialSetup }) {
    return `
    <category name="%{BKY_CATEGORY_CONTROL}" id="control" colour="#FFAB19" secondaryColour="#CF8B17">
        <block type="control_wait">
            <value name="DURATION">
                <shadow type="math_positive_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="control_repeat">
            <value name="TIMES">
                <shadow type="math_whole_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>
        <block id="forever" type="control_forever"/>
        ${blockSeparator}
        <block type="control_if"/>
        <block type="control_if_else"/>
        <block type="control_if_else_special"  id="control_if_else_special"/>
        <block id="wait_until" type="control_wait_until"/>
        <block id="repeat_until" type="control_repeat_until"/>
        ${blockSeparator}
    </category>
    `;
};

const operators = function ({ isInitialSetup }) {
    const apple = ScratchBlocks.ScratchMsgs.translate('OPERATORS_JOIN_APPLE', 'apple');
    const banana = ScratchBlocks.ScratchMsgs.translate('OPERATORS_JOIN_BANANA', 'banana');
    const letter = ScratchBlocks.ScratchMsgs.translate('OPERATORS_LETTEROF_APPLE', 'a');
    return `
    <category name="%{BKY_CATEGORY_OPERATORS}" id="operators" colour="#40BF4A" secondaryColour="#389438">
        <block type="operator_add">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        <block type="operator_subtract">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        <block type="operator_multiply">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        <block type="operator_divide">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="operator_random">
            <value name="FROM">
                <shadow type="math_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
            <value name="TO">
                <shadow type="math_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="operator_gt">
            <value name="OPERAND1">
                <shadow type="text">
                    <field name="TEXT"/>
                </shadow>
            </value>
            <value name="OPERAND2">
                <shadow type="text">
                    <field name="TEXT">50</field>
                </shadow>
            </value>
        </block>
        
        <block type="operator_gt_equals" id="operator_gt_equals">
            <value name="OPERAND1">
                <shadow type="text">
                    <field name="TEXT"/>
                </shadow>
            </value>
            <value name="OPERAND2">
                <shadow type="text">
                    <field name="TEXT"/>
                </shadow>
            </value>
        </block>
        <block type="operator_lt">
            <value name="OPERAND1">
                <shadow type="text">
                    <field name="TEXT"/>
                </shadow>
            </value>
            <value name="OPERAND2">
                <shadow type="text">
                    <field name="TEXT">50</field>
                </shadow>
            </value>
        </block>
        <block type="operator_lt_equals" id="operator_lt_equals">
            <value name="OPERAND1">
                <shadow type="text">
                    <field name="TEXT"/>
                </shadow>
            </value>
            <value name="OPERAND2">
                <shadow type="text">
                    <field name="TEXT"/>
                </shadow>
            </value>
        </block>
        <block type="operator_equals">
            <value name="OPERAND1">
                <shadow type="text">
                    <field name="TEXT"/>
                </shadow>
            </value>
            <value name="OPERAND2">
                <shadow type="text">
                    <field name="TEXT">50</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="operator_and"/>
        <block type="operator_or"/>
        <block type="operator_not"/>
        ${blockSeparator}
        ${isInitialSetup
            ? ""
            : `
            <block type="operator_join">
                <value name="STRING1">
                    <shadow type="text">
                        <field name="TEXT">${apple} </field>
                    </shadow>
                </value>
                <value name="STRING2">
                    <shadow type="text">
                        <field name="TEXT">${banana}</field>
                    </shadow>
                </value>
            </block>
            <block type="operator_letter_of">
                <value name="LETTER">
                    <shadow type="math_whole_number">
                        <field name="NUM">1</field>
                    </shadow>
                </value>
                <value name="STRING">
                    <shadow type="text">
                        <field name="TEXT">${apple}</field>
                    </shadow>
                </value>
            </block>
            <block type="operator_length">
                <value name="STRING">
                    <shadow type="text">
                        <field name="TEXT">${apple}</field>
                    </shadow>
                </value>
            </block>
            <block type="operator_contains" id="operator_contains">
              <value name="STRING1">
                <shadow type="text">
                  <field name="TEXT">${apple}</field>
                </shadow>
              </value>
              <value name="STRING2">
                <shadow type="text">
                  <field name="TEXT">${letter}</field>
                </shadow>
              </value>
            </block>
             <block type="operator_textsrobotseven" id="operator_textsrobotseven">
        <value name="STRING1">
            <shadow type="text">
            <field name="TEXT">apple</field>
            </shadow>
        </value>
        <value name="STRING2">
            <shadow type="math_number">
            <field name="NUM">1</field>
            </shadow>
        </value>
        <value name="STRING3">
            <shadow type="math_number">
            <field name="NUM">2</field>
            </shadow>
        </value>
        </block>
        <block type="operator_textsrobotsevenNew" id="operator_textsrobotsevenNew">
        <value name="STRING1">
            <shadow type="text">
            <field name="TEXT">ap</field>
            </shadow>
        </value>
        <value name="STRING2">
            <shadow type="text">
            <field name="TEXT">apple</field>
            </shadow>
        </value>
        </block>
        `
        }
        ${blockSeparator}
        <block type="operator_mod">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        <block type="operator_round">
            <value name="NUM">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="operator_stringToNum" id="operator_stringToNum">
             <value name="STRING">
                 <shadow type="text">
                     <field name="TEXT">123</field>
                 </shadow>
             </value>
        </block>
        <block type="operator_mathop" id="operator_mathop">
            <value name="NUM">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="operator_numToASCII" id="operator_numToASCII">
             <value name="NUM">
                 <shadow type="math_number">
                     <field name="NUM">0</field>
                 </shadow>
             </value>
         </block>
         <block type="operator_stringToASCII" id="operator_stringToASCII">
             <value name="STRING">
                 <shadow type="text">
                     <field name="TEXT">a</field>
                 </shadow>
             </value>
         </block>
         <block type="operator_numToString" id="operator_numToString">
             <value name="NUM">
                 <shadow type="math_number">
                     <field name="NUM">132</field>
                 </shadow>
             </value>
         </block>
         ${blockSeparator}
         <block type="operator_map" id="operator_map">
         <value name="VALUE">
           <shadow type="math_number">
             <field name="NUM">0</field>
           </shadow>
         </value>
         <value name="FROMLOW">
           <shadow type="math_number">
             <field name="NUM">0</field>
           </shadow>
         </value>
         <value name="FROMHIGH">
           <shadow type="math_number">
             <field name="NUM">1023</field>
           </shadow>
         </value>
         <value name="TOLOW">
           <shadow type="math_number">
             <field name="NUM">0</field>
           </shadow>
         </value>
         <value name="TOHIGH">
           <shadow type="math_number">
             <field name="NUM">255</field>
           </shadow>
         </value>
       </block>
       <block type="operator_constrain" id="operator_constrain">
         <value name="VALUE">
           <shadow type="math_number">
             <field name="NUM">0</field>
           </shadow>
         </value>
         <value name="MIN">
           <shadow type="math_number">
             <field name="NUM">0</field>
           </shadow>
         </value>
         <value name="MAX">
           <shadow type="math_number">
             <field name="NUM">100</field>
           </shadow>
         </value>
       </block>
       ${categorySeparator}
    </category>
    `;
};

const variables = function (targetId) {
    //传入targetId是新建项目后，能正确刷新toolbox
    return `
    <category
        name="%{BKY_CATEGORY_VARIABLES}"
        id="variables"
        colour="#FF8C1A"
        targetId="${targetId}"
        secondaryColour="#DB6E00"
        custom="STRING_NUMBER_VARIABLE">
    </category>
    `;
};

const myBlocks = function () {
    return `
    <category
        name="%{BKY_CATEGORY_MYBLOCKS}"
        id="myBlocks"
        colour="#FF6680"
        secondaryColour="#FF4D6A"
        custom="PROCEDURE">
    </category>
    `;
};
/* eslint-enable no-unused-vars */

const xmlOpen = '<xml style="display: none">';
const xmlClose = '</xml>';

/**
 * @param {!boolean} isInitialSetup - Whether the toolbox is for initial setup. If the mode is "initial setup",
 * blocks with localized default parameters (e.g. ask and wait) should not be loaded. (LLK/scratch-gui#5445)
 * @param {?boolean} isStage - Whether the toolbox is for a stage-type target. This is always set to true
 * @param {?boolean} isDevice
 * when isInitialSetup is true.
 * @param {?string} targetId - The current editing target
 * @param {?Array.<object>} categoriesXML - optional array of `{id,xml}` for categories. This can include both core
 * and other extensions: core extensions will be placed in the normal Scratch order; others will go at the bottom.
 * @property {string} id - the extension-button / category ID.
 * @property {string} xml - the `<category>...</category>` XML for this extension-button / category.
 * @param {?string} costumeName - The name of the default selected costume dropdown.
 * @param {?string} backdropName - The name of the default selected backdrop dropdown.
 * @param {?string} soundName -  The name of the default selected sound dropdown.
 * @returns {string} - a ScratchBlocks-style XML document for the contents of the toolbox.
 */
const makeToolboxXML = function (isInitialSetup, targetId = '', categoriesXML: any[] = [],
    costumeName = '', backdropName = '', soundName = '') {
    const gap = [categorySeparator];

    costumeName = xmlEscape(costumeName);
    backdropName = xmlEscape(backdropName);
    soundName = xmlEscape(soundName);

    categoriesXML = categoriesXML.slice();
    const moveCategory = categoryId => {
        const index = categoriesXML.findIndex(categoryInfo => categoryInfo.id === categoryId);
        if (index >= 0) {
            // remove the category from categoriesXML and return its XML
            const [categoryInfo] = categoriesXML.splice(index, 1);
            return categoryInfo.xml;
        }
        // return `undefined`
    };
    const searchXML = moveCategory('search') || search(targetId);
    const controlXML = moveCategory('control') || control({ isInitialSetup });
    const operatorsXML = moveCategory('operators') || operators({ isInitialSetup });
    const variablesXML = moveCategory('data') || variables(targetId);
    const myBlocksXML = moveCategory('procedures') || myBlocks();

    const everything = [
        xmlOpen,
        searchXML, gap,
        controlXML, gap,
        operatorsXML, gap,
        variablesXML, gap,
        myBlocksXML
    ];

    for (const extensionCategory of categoriesXML) {
        everything.push(gap, extensionCategory.xml);
    }

    everything.push(xmlClose);
    return everything.join('\n');
};

export default makeToolboxXML;
