import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store";
import {editorMessages, locales} from "lib/scratch-l10n";
import {createIntl, createIntlCache} from 'react-intl';


// Define a type for the slice state
interface IntlState {
    locale: string
    messages: { [id: string]: any }
    messagesByLocale: any
    isRtl: boolean
}

// 初始化语言, 1.用户上次选择的语言, 2.用户当前地区的语言 
let initLang = localStorage.getItem('locale') || navigator.language.toLowerCase();;
if (!locales[initLang]) initLang = "zh-cn";
 
// Define the initial state using that type
const initialState: IntlState = {
    locale: initLang,
    messages: editorMessages[initLang],
    messagesByLocale: editorMessages,
    isRtl: false
}
const cache = createIntlCache();
export let intl = createIntl(
    {
        locale: initLang,
        messages: editorMessages[initLang],
    },
    cache
);
export const intlSlice = createSlice({
    name: 'intl',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        changeLocale: (state, action: PayloadAction<string>) => {
            if (editorMessages[action.payload]) {
                state.locale = action.payload;
                state.messages = editorMessages[action.payload];
                localStorage.setItem('locale', action.payload);
                // 非组件环境intl切换
                intl = createIntl({
                    locale: action.payload,
                    messages: editorMessages[action.payload]
                })
            }
        },
        updateLocalMessages: (state, action: PayloadAction<any>) => {
            state.messagesByLocale = action.payload
            state.messages = action.payload[state.locale === 'zh' ? 'zh-cn' : state.locale]
        }
    },
})

// Other code such as selectors can use the imported `RootState` type
export const selectIntl = (state: RootState) => state.intl

export const {changeLocale, updateLocalMessages} = intlSlice.actions;
export default intlSlice.reducer
